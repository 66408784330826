/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserRequest } from "../models/CreateUserRequest";
import type { RoleDTO } from "../models/RoleDTO";
import type { UpdateUserRoleRequest } from "../models/UpdateUserRoleRequest";
import type { UserDTO } from "../models/UserDTO";
import type { UserInformationDTO } from "../models/UserInformationDTO";
import type { UserRolesDTO } from "../models/UserRolesDTO";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class UsersService {
  /**
   * @returns UserDTO
   * @throws ApiError
   */
  public static usersGetAll(): CancelablePromise<Array<UserDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Users",
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static usersPost(
    requestBody: Array<CreateUserRequest>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Users",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id
   * @returns UserDTO
   * @throws ApiError
   */
  public static usersGet(id: number): CancelablePromise<UserDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Users/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static usersDelete(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/Users/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static usersUpdate(
    id: number,
    requestBody: CreateUserRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Users/update/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static usersRemoveRole(
    requestBody: UpdateUserRoleRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Users/removeRole",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static usersAddRole(
    requestBody: UpdateUserRoleRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Users/addRole",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns RoleDTO
   * @throws ApiError
   */
  public static usersGetAllRoles(): CancelablePromise<Array<RoleDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Users/GetAllRoles",
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static usersUpdateUsersRoles(
    requestBody: Array<UserRolesDTO>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Users/UpdateUsersRoles",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static usersInviteUsersWithRoles(
    requestBody: Array<CreateUserRequest>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Users/InviteUsersWithRoles",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param instanceId
   * @returns any
   * @throws ApiError
   */
  public static usersGetBackgroundProcessStatus(
    instanceId: string | null
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Users/BackgroundProcessStatus/{instanceId}",
      path: {
        instanceId: instanceId,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static usersUserExists(requestBody: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Users/UserExists",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static usersLastLoggedIn(requestBody: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Users/LastLoggedIn",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static usersGetTotalAssignedUsers(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Users/GetTotalAssignedUsers",
    });
  }

  /**
   * @param azureIdentityId
   * @param unrestrictedAccess
   * @returns RoleDTO
   * @throws ApiError
   */
  public static usersGetUserRoles(
    azureIdentityId?: string | null,
    unrestrictedAccess?: boolean
  ): CancelablePromise<Array<RoleDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Users/GetUserRoles",
      query: {
        azureIdentityId: azureIdentityId,
        unrestrictedAccess: unrestrictedAccess,
      },
    });
  }

  /**
   * @param azureIdentityId
   * @returns UserInformationDTO
   * @throws ApiError
   */
  public static usersGetUserInformation(
    azureIdentityId?: string | null
  ): CancelablePromise<UserInformationDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Users/GetUserInformation",
      query: {
        azureIdentityId: azureIdentityId,
      },
    });
  }

  /**
   * @param userEmail
   * @returns void
   * @throws ApiError
   */
  public static usersAddAccountOwner(
    userEmail?: string | null
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Users/AddAccountOwner",
      query: {
        userEmail: userEmail,
      },
    });
  }
}
