import React from "react";

type Props = {
  abbr: string;
  imageUrl?: string | null;
  width?: number;
  height?: number;
};

/**
 * BST Styled Avatar
 * @param param - avatar props
 * @returns BST Styled Avatar image or text abbreviation
 */
export function Avatar({
  abbr,
  width,
  height,
  imageUrl
}: Props) {
  return (
    <div
      className="global-utilities__employee-avatar"
      data-testid="employee-avatar"
      style={{ width, height }}
    >
      {imageUrl ? (
        <img
          data-testid="avatar-image"
          alt={abbr}
          src={imageUrl}
          width={width || 32}
          height={height || 32}
        />
      ) : (
        abbr
      )}
    </div>
  );
}
