import { useState } from "react";

import HyperFocus from "../../HyperFocus/Views/HyperFocus";
import { HyperFocusCloseBtn } from "../../HyperFocus/Views/HyperFocusCloseBtn";
import { useTranslation } from "react-i18next";

import { menuItems, MenuItemID, PROFILE_MENU_ITEM } from "../utils/mySettingsMenu";
import { ProfileSection } from "./ProfileSection";

export interface Props {
  show: boolean;
  handleShowModal: () => void;
}

export const MobileMySettings = ({ show, handleShowModal }: Props) => {
  const { t } = useTranslation();

  const [activeMenu, setActiveMenu] = useState<MenuItemID>(PROFILE_MENU_ITEM);

  const handleMenuItemClick = (menuItem: MenuItemID) => {
    const target = document.getElementById(menuItem);
    target?.scrollIntoView({ behavior: "smooth" });
    setActiveMenu(menuItem);
  }

  return (
    <>
      <HyperFocus
        hideModalHeader
        handleShowModal={handleShowModal}
        title={t("mySettings")}
        show={show}
        className="my-settings-mobile"
      >
        <div className="my-settings-mobile__header">
          <div className="my-settings-mobile__header-title">
            <span>{t("mySettings")}</span>
            <HyperFocusCloseBtn onCloseHandler={handleShowModal} />
          </div>
          <div
            className="my-settings-mobile__tabs"
          >
            {menuItems.map((item) => (
              <div
                key={item.id}
                className={`my-settings-mobile__tab ${activeMenu === item.id ? "is-active" : ""}`}
                data-testid={`my-settings-mobile-menu-item-${item.id}`}
                onClick={() => handleMenuItemClick(item.id)}
              >
                <span>{item.title}</span>
              </div>
            ))}
          </div>
        </div>
        <div
          id="my-settings-mobile__body"
          className="my-settings-mobile__body"
          data-testid="my-settings-mobile-body"
        >
          <div className="my-settings-mobile__content scrollbar-visible">
            <div
              id={PROFILE_MENU_ITEM}
              data-testid={PROFILE_MENU_ITEM}
              className="my-settings-mobile__content-body"
            >
              <ProfileSection />
            </div>
          </div>
        </div>
      </HyperFocus>
    </>
  );
};
