import React, { useRef } from "react";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";

export interface Props {
  visibility: any;
  title?: any;
  content?: any;
  target?: string;
  timeOut?: number;
  position?: object;
}

/**
 *
 * The Toast is a small container in which user can show a single or multiple informative line with actions.
 *
 * @param visibility - Boolean used change the component visibility when it's created
 * @param title - Specifies the title to be displayed on the Toast. Accepts selectors, string values and HTML elements.
 * @param content - Specifies the content to be displayed on the Toast. Accepts selectors, string values and HTML elements
 * @param target - Specifies the target container where the Toast will be displayed.
 * @param timeOut - Specifies the Toast display time duration on the page in milliseconds
 * @param position - Specifies the position of the Toast message to be displayed within target container.
 *
 * @remarks
 * Syncfusion Toast documentation {@link https://ej2.syncfusion.com/react/documentation/toast}.
 *
 **/

const ToastNotification = ({
  visibility,
  title,
  target,
  content,
  timeOut,
  position,
}: Props) => {
  const toastInstance = useRef<ToastComponent>(null);

  /* istanbul ignore next */
  const toastShow = (instance: ToastComponent) => {
    if (visibility) {
      instance.show();
    }
  };

  /* istanbul ignore next */
  const toastCreated = () => {
    if (toastInstance && toastInstance.current) {
      toastShow(toastInstance.current);
    }
  };

  return (
    <React.Fragment>
      {visibility && (
        <ToastComponent
          data-testid="ToastNotification"
          title={title}
          target={target}
          content={content}
          ref={toastInstance}
          created={toastCreated}
          timeOut={timeOut}
          position={position}
        />
      )}
    </React.Fragment>
  );
};

export default ToastNotification;
