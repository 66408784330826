import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";

const Authorized = ({ children }: { children: any }) => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <React.Fragment>
      {isAuthenticated && (
        <>{children}</>
      )}
      {!isAuthenticated && <span data-testid="not-auth">Not Authenticated</span>}
    </React.Fragment>
  );
};
export default Authorized;
