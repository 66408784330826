import React from "react";
import { Modal } from "react-bootstrap";

import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Freeze, DataResult, SelectionSettingsModel, TextAlign } from '@syncfusion/ej2-react-grids';
import { DataManager } from '@syncfusion/ej2-data';

export interface Props {
    show: boolean;
    handleShowModal?: () => void;
    title?: string;
    children: any;
    headerContentRight?: any;
    footerContent?: any;
}


/**
 *
 * Add dialogs to your site for lightboxes, user notifications, or completely custom content.
 * @param title - Header title of modal Header
 * @param show - Boolean used to show if modal is open
 * @param handleShowModal - Function used to handle open/close modal
 * @param headerContentRight - Content render inside right side of header
 * @param footerContent - Content render inside footer
 * @param children - Content rendered inside modal body
 *
 * @remarks 
 * React Bootstrap Modal documentation {@link https://react-bootstrap.github.io/components/modal/}.
 *
 **/


const HyperFocusBottom = ({ title, show, handleShowModal, headerContentRight, footerContent, children }: Props) => {
    return (
        <React.Fragment>
            <Modal
                show={show}
                onHide={handleShowModal}
                className="global-utilities__hyperFocus--bottom"
                dialogClassName="content-center dialog"
                animation={false}
                keyboard={true}
                size='sm'
            >
                <Modal.Header>
                    <div className="modal-header-left">
                        <Modal.Title className="heading-modal">
                            {title}
                        </Modal.Title>
                    </div>
                    <div className="modal-header-right">
                        {headerContentRight}
                    </div>
                </Modal.Header>

                <Modal.Body>
                    {children}
                </Modal.Body>
                {footerContent &&(
                    <Modal.Footer>
                        {footerContent}
                    </Modal.Footer>
                )}
            </Modal>
        </React.Fragment>
    );
};

export default HyperFocusBottom;