import { useAccount, useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import UserManagement from "../../../pages/Views/UserManagement";
import AppSettings from "../../AppSettings/Views/AppSettings";
import { MySettings } from "../../MySettings/Views/MySettings";
import HyperFocus from "../../HyperFocus/Views/HyperFocus";
import useProfileMenu from "../Hooks/useProfileMenu";
import { testIsSupportedWidth } from "../../ContentNotSupported/Views/ContentNotSupported";

export interface Props {
  /**
   * Optional handle logout
   */
  handleLogout: () => void;
}

/**
 *  Profile menu options
 * @param handleLogout - function to handle the logging a user out
 * @returns
 */
const ProfileMenu = ({ handleLogout }: Props) => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const {
    showInsightAppSettingsModal,
    showUserManagementModal,
    handleShowInsightAppSettingModal,
    handleShowUserManagement,
    showUserManagement,
    UserInformation,
    showInsightAppSettings,
    showMySettings,
    handleShowMySettingsModal
  } = useProfileMenu();
  const { t } = useTranslation();
  const [dataLetters, setDataLetters] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (account && account.name) {
      const splitName = account.name.split(" ");
      setDataLetters(splitName[0].charAt(0) + splitName[1].charAt(0));
    }
  }, [account]);

  return (
    <React.Fragment>
      <Dropdown
        show={show}
        onToggle={() => setShow(!show)}
        align="end"
        className="global-utilities__item  dropdown-profile-menu-icon"
      >
        <Dropdown.Toggle
          className={`button__utility global-utilities__button ${show ? 'is-active' : ''}`}
          name="dropdown"
          data-testid="user-profile-toggle"
          id="dropdown-basic"
          aria-label="views"
        >
          <span
            className="global-utilities__button__icon"
            aria-hidden="true"
            title="User Profile"
            data-testid="user-profile"
          >
            <i className="icon icon-profile"></i>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown--user dropdown">
          <Dropdown.Header className="dropdown__header">
            <span
              className="dropdown__user-initials"
              data-letters={dataLetters}
            />
            <div className="dropdown__name-wrap">
              <h1 className="dropdown__name" data-testid="user-name">
                {UserInformation.userName}
              </h1>
              <h2 className="dropdown__title" data-testid="user-title">
                {UserInformation.title}
              </h2>
            </div>
          </Dropdown.Header>
          <div className="dropdown-profile-menu-wrapper dropdown__items-wrap">
            <Dropdown.Item
              eventKey="2"
              className="dropdown__item"
              data-testid="My Settings"
              onClick={handleShowMySettingsModal}
            >
              <span
                className="dropdown__item__icon"
                aria-hidden="true"
                title="My Settings"
              >
                <i className="icon icon-settings"></i>
              </span>
              <span className="dropdown__item__label">{t("mySettings")}</span>
            </Dropdown.Item>
            {showInsightAppSettings && (
              <Dropdown.Item
                eventKey="3"
                className="dropdown__item u-hide-md-down"
                data-testid="Insight App Settings"
                onClick={() => handleShowInsightAppSettingModal()}
              >
                <span
                  className="dropdown__item__icon"
                  aria-hidden="true"
                  title="Insight App Settings"
                >
                  <i className="icon icon-appsettings"></i>
                </span>
                <span className="dropdown__item__label">
                  {t("appSettings")}
                </span>
              </Dropdown.Item>
            )}
            {showUserManagement && (
              <Dropdown.Item
                eventKey="4"
                className="dropdown__item u-hide-md-down"
                data-testid="user-profile-button_user_management"
                onClick={() => handleShowUserManagement()}
              >
                <span
                  className="dropdown__item__icon"
                  aria-hidden="true"
                  title="User Management"
                >
                  <i className="icon icon-usersettings"></i>
                </span>
                <span className="dropdown__item__label">
                  {t("userManagement")}
                </span>
              </Dropdown.Item>
            )}
            <Dropdown.Item
              eventKey="5"
              className="dropdown__item"
              onClick={() => {
                window.open(
                  "https://insightssupport.bstglobal.com/help/Default.htm ",
                  "_blank",
                  "noopener"
                );
              }}
            >
              <span
                className="dropdown__item__icon"
                aria-hidden="true"
                title="Help"
              >
                <i className="icon icon-help"></i>
              </span>
              <span className="dropdown__item__label">{t("help")}</span>
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="6"
              className="dropdown__item"
              data-testid="user-profile-button_log-out"
              onClick={() => handleLogout()}
            >
              <span
                className="dropdown__item__icon"
                aria-hidden="true"
                title="Logout"
              >
                <i className="icon icon-logout"></i>
              </span>
              <span className="dropdown__item__label">{t("logout")}</span>
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <AppSettings
        show={showInsightAppSettingsModal}
        handleShowModal={handleShowInsightAppSettingModal}
      />
      {showMySettings && (
        <MySettings
          show={showMySettings}
          handleShowModal={handleShowMySettingsModal}
        />
      )}
      <HyperFocus
        title="User Management"
        idBody="user-management-hyperfocus"
        handleShowModal={handleShowUserManagement}
        show={showUserManagementModal && testIsSupportedWidth()}
      >
        <UserManagement isHyperFocus={showUserManagementModal} />
      </HyperFocus>
      {show && <div className="user-profile-overlay"></div>}
    </React.Fragment>
  );
};

export default ProfileMenu;
