import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MobileMySettings } from '../../MySettings/Views/MobileMySettings';

interface MobileMenuProps {
  show: boolean;
  /**
   * Optional click handler for button close
   */
  onCloseClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;

  /**
   * Optional handle logout
   */
  handleLogout: () => void;
}

const MobileMenu = ({ show, onCloseClick, handleLogout }: MobileMenuProps) => {
  const { t } = useTranslation();
  const [showMySettings, setShowMySettings] = useState(false);

  return (
    <React.Fragment>
      <div
        className={show ? "backdrop active" : "backdrop"}
        data-testid="mobile-menu-backdrop"
      >
        <div className="menu-container">
          <div className="menu-container-primary-section">
            <div className="menu-top-section">
              <Link
                to="/"
                className="app-home is-active"
                tabIndex={-1}
                data-testid="header__logo"
              >
                <span
                  className="menu-fill-white app-home__logo"
                  title="Insights App"
                >
                  <i className="icon icon-bst_solid"></i>
                </span>
                <span className="menu-title app-home__app-name">
                  Insights App
                </span>
              </Link>
              <button
                className="l-header__right menu-close-icon"
                aria-label="Close"
                title="Close"
                data-testid="header__close_icon"
                onClick={onCloseClick}
              >
                <i className="menu-fill-white icon icon-close"></i>
              </button>
            </div>
          </div>
          <div className="menu-container-secondary-section">
            <div className="menu-picker-row">
              <span
                className="menu-dropdown__item_menu"
                aria-label="My Settings"
                tabIndex={-1}
                data-testid="user-settings"
                onClick={() => setShowMySettings(!showMySettings)}
              >
                <span
                  className="menu-secondary-label-icon"
                  aria-hidden="true"
                  title="My Settings"
                >
                  <i className="icon icon-settings"></i>
                </span>
                <span
                  className="menu-secondary-label"
                  data-testid="menu_label_mySettings"
                >
                  {t("mySettings")}
                </span>
              </span>
            </div>
            <div className="menu-picker-row">
              <a
                className="menu-dropdown__item_menu"
                href="https://insightssupport.bstglobal.com/help/Default.htm"
                aria-label="Help"
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={-1}
                data-testid="user-help"
              >
                <span
                  className="menu-secondary-label-icon"
                  aria-hidden="true"
                  title="Help"
                >
                  <i className="icon icon-help"></i>
                </span>
                <span
                  className="menu-secondary-label"
                  data-testid="menu_label_help"
                >
                  {t("help")}
                </span>
              </a>
            </div>
            <div className="menu-picker-row">
              <a
                className="menu-dropdown__item_menu ignore-click"
                aria-label="Apps"
                href="apps"
                tabIndex={-1}
                data-testid="user-apps"
              >
                <span
                  className="menu-secondary-label-icon"
                  aria-hidden="true"
                  title="@StringResources.Apps"
                >
                  <i className="icon icon-all-apps"></i>
                </span>
                <span
                  className="menu-secondary-label"
                  data-testid="menu_label_apps"
                >
                  Apps
                </span>
              </a>
            </div>
            <div className="menu-line-white"></div>
            <div className="menu-picker-row">
              <button
                className="menu-dropdown__item_menu menu-logout"
                aria-label="LogOut"
                tabIndex={-1}
                data-testid="user-profile-mobile_button-logout"
                onClick={() => handleLogout()}
              >
                <span
                  className="menu-secondary-label-icon"
                  aria-hidden="true"
                  title="LogOut"
                >
                  <i className="icon icon-logout"></i>
                </span>
                <span
                  className="menu-secondary-label"
                  data-testid="menu_label_logout"
                >
                  {t("logout")}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <MobileMySettings
        show={showMySettings}
        handleShowModal={() => setShowMySettings(!showMySettings)}
      />
    </React.Fragment>
  );
};

export default MobileMenu;
