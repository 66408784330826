import { useState, useContext } from "react";
import { BSTInsightsAppContext } from "../../../layouts/PageLayout";
import { RolesEnum } from "../../../utils/roles";

/**
 * useProfileMenu
 *
 * @returns
 *
 *```
 *{
 *  showInsightAppSettingsModal,
 *  showUserManagementModal,
 *  handleShowInsightAppSettingModal,
 *  handleShowUserManagement
 *}
 *```
 *
 **/
const useProfileMenu = () => {
  const [showInsightAppSettingsModal, setShowInsightAppSettingsModal] =
    useState(false);
  const [showUserManagementModal, setShowUserManagementModal] = useState(false);
  const [showMySettings, setShowMySettings] = useState(false);
  const { UserInformation, UserRoles } = useContext(BSTInsightsAppContext);

  const showUserManagement =
    UserRoles.length > 1 &&
    UserRoles.some((item) => item.roleId === RolesEnum["Account Owner"]);

  const showInsightAppSettings = UserRoles.some(
    (item) =>
      item.roleId === RolesEnum["Account Owner"] ||
      item.roleId === RolesEnum["Project Administator"]
  );

  const userRoles = UserRoles.map((item) => item.roleName);

  const handleShowInsightAppSettingModal = () => {
    setShowInsightAppSettingsModal(!showInsightAppSettingsModal);
  };

  const handleShowUserManagement = () => {
    setShowUserManagementModal(!showUserManagementModal);
  };

  const handleShowMySettingsModal = () => setShowMySettings(!showMySettings);

  return {
    showInsightAppSettingsModal,
    showUserManagementModal,
    handleShowInsightAppSettingModal,
    handleShowUserManagement,
    showUserManagement,
    userRoles,
    UserInformation,
    showInsightAppSettings,
    showMySettings,
    handleShowMySettingsModal,
  };
};
export default useProfileMenu;
