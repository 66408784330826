import React, { useState, useEffect, useContext } from "react";
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import SummaryTop from "../../components/SummaryTop/Views/SummaryTop";
import Grid from "../../components/Grid/Views/Grid";
import Authorized from "../../layouts/Authorized";
import useUserManagement from "../Hooks/useUserManagement";
import HyperFocusBottom from "../../components/HyperFocusBottom/HyperFocusBottom";
import useAddUser from "../../components/User/Hooks/useAddUser";
import AddUser from "../../components/User/Views/AddUser";
import ToastNotification from "../../components/ToastNotification/ToastNotification";
import ProjectHeader from "../../components/ProjectHeader/Views/ProjectHeader";
import { Modal } from "react-bootstrap";
import { GridComponent } from "@syncfusion/ej2-react-grids";
import { useTranslation } from "react-i18next";
import { TodayMarkerContext } from "../../layouts/PageLayout";



type propsUserManagement = {
  isHyperFocus?: boolean;
}
/**
*
* Render UserManagement component with summary, header title and grid
*
* @param isHyperFocus - Boolean that specifies if the page content will show in a Hyperfocus body
*
**/
const UserManagement = ({ isHyperFocus }: propsUserManagement) => {
  let gridInstance = React.useRef<GridComponent | null>(null);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [updateTotals, setUpdateTotals] = useState<any>(false);
  const [toastContent, setToastContent] = useState<string>('');
  const toastTimeOUt = 3000

  const getRow = (id: number) => {
    const ds: any = gridInstance.current?.dataSource || [];
    return ds.find((p: any) => p.uid === id);
  }

  const refreshGridData = (name: string, email: string, id: number, e: any) => {
    const item = getRow(id);
    if (item) {
      item.email = email || item.email;
      item.name = name || item.name;
    }
  }

  const updateRolesOnGrid = (id: number, roles: any) => {
    const item = getRow(id);
    if (item) {
      item.groups = roles.map((p: any) => `roleGroup${p}`);
      let forceUpdate = false
      if(item.accountStatusName === 'New' && item.groups.length > 0) {
        item.accountStatusName = 'Invitation Not Sent'
        forceUpdate = true
      }
      if(item.accountStatusName === 'Invitation Not Sent' && item.groups.length === 0) {
        item.accountStatusName = 'New'
        forceUpdate = true
      }
      if (gridInstance.current?.dataSource && forceUpdate)
        gridInstance.current.dataSource = [...(gridInstance.current.dataSource as object[])]
    }
  }

  const {
    dataSource,
    columns,
    isLoading,
    selectedRows,
    handleShowAddUser,
    showAddUserModal,
    clearSelectedRows,
    onSendInvites,
    rowDataBound,
    modalInvitationsVisible,
    swicthChecked,
    handleShowModalInvitation,
    handleSwitchButton,
    filterColumns,
    showSuccessMessage,
    loadData,
  } = useUserManagement(isHyperFocus, refreshGridData, updateRolesOnGrid, setUpdateTotals, gridInstance);
  const state = useAddUser();
  const { t } = useTranslation();
  const { handleSubmitButton, disabledButton, handleResetForm, isSendInvitationEnabled } = state
  const { setMobileSizeRestriction } = useContext(TodayMarkerContext);

  useEffect(() => {
    setMobileSizeRestriction(true)
    return () => setMobileSizeRestriction(false)
  }, [])

  const submitNewUser = async () => {
    const result = await handleSubmitButton(swicthChecked && isSendInvitationEnabled)
    if (result?.error) {
      //TODO: display error message
    } else {
      setToastContent(t("userManagementPage.userAdd_success"))
      setToastVisible(true)
      handleShowAddUser();
      handleResetForm();
      handleSwitchButton();
      setUpdateTotals(new Date());
      loadData()
    }
  }

  const updateToSendingInvitation = () => {
    var grid = gridInstance?.current;
    selectedRows.map((item: any) => {
      const row = getRow(item.uid);
      row.accountStatusName = 'Sending Invitation'
    })
    if (grid?.dataSource)
      grid.dataSource = [...(grid.dataSource as object[])]
    clearSelectedRows()
  }

  const sendInvites = async () => {
    setTimeout(() => {
      handleShowModalInvitation()
      updateToSendingInvitation()
    }, 1000)
    await onSendInvites(gridInstance?.current)

    setUpdateTotals(true)
    setTimeout(() => { setUpdateTotals(false) }, 1000)
  }

  const switchButton = (
    <div className="button__switch">
      <span className="label">{t("userManagementPage.sendInvitation_btn")}</span>
      <SwitchComponent
        id="switch"
        data-testid="switch-send-invitation"
        enableRtl={true}
        disabled={!isSendInvitationEnabled}
        checked={swicthChecked}
        cssClass="switch"
        onChange={handleSwitchButton}
      />
    </div>
  )


  const footerButtons = (
    <React.Fragment>
      <button
        data-testid="btnCancelSubmitNewUser"
        className="button button--secondary"
        onClick={() => { handleShowAddUser(); handleResetForm(); }}
      >
        {t("userManagementPage.cancel_btn")}
        <i className="icon icon-close"></i>
      </button>
      <button
        className=" button button--primary"
        data-testid="btnSubmitNewUser"
        onClick={() => submitNewUser()}
        disabled={disabledButton}
      >
        {t("userManagementPage.create_btn")}
        <i className="icon icon-check"></i>
      </button>
    </React.Fragment>
  )

  return (
    <>
      <Authorized>
        <div className="user-management">
          {showSuccessMessage && (
            <div className="alert alert--success position-relative">
              <i className="icon icon-check"></i>
              <span>{t("userManagementPage.operation_completed_message")}</span>
            </div>
          )}
          <SummaryTop updateTotals={updateTotals} />
          <ProjectHeader
            className="user-management-subheader"
            title={t("userManagementPage.allUsers_title")}
            hasAddButton={true}
            hasTitle={true}
            id='titlePage'
            data-testid='titlePage'
            addButtonHandler={() => handleShowAddUser()}
          />
          <div className="grid-container-outside">
            <Grid
              gridInstance={gridInstance}
              id='gridData'
              isLoading={isLoading}
              frozenColumns={3}
              rowDataBound={rowDataBound}
              selectionSettings={{
                checkboxMode: 'Default',
                checkboxOnly: true,
                type: 'Multiple',
                // persistSelection: true,
                enableSimpleMultiRowSelection: true,
              }}
              columns={columns}
              dataSource={dataSource}
              showFooter={selectedRows.length > 0}
              selectedRowsNumber={selectedRows.length}
              onSendInvites={sendInvites}
              selectAll={selectAll}
              filterColumns={filterColumns}
            />
            <HyperFocusBottom
              title={t("userManagementPage.newUser_title")}
              handleShowModal={() => null}
              show={showAddUserModal}
              headerContentRight={switchButton}
              footerContent={footerButtons}
            >
              <AddUser state={state} />
            </HyperFocusBottom>

            <ToastNotification
              visibility={toastVisible}
              content={toastContent}
              timeOut={toastTimeOUt}
              position={{ X: "Right", Y: "Bottom" }}
            />
          </div>

          <Modal
            show={modalInvitationsVisible}
            onHide={handleShowModalInvitation}
            dialogClassName="invitations-modal__dialog"
            contentClassName="invitations-modal__content"
            className="invitations-modal"
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Body className="invitations-modal__body">
              <h2 className="heading-xxl">{t("userManagementPage.modalInvite_title")}</h2>
              <p className="lead">{t("userManagementPage.modalInvite_subtitle")}</p>
              <button className="DNA-button" onClick={handleShowModalInvitation}>{t("userManagementPage.ok_btn")}</button>
            </Modal.Body>
          </Modal>

        </div>
      </Authorized>
    </>
  );
};
export default UserManagement;
