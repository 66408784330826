import React, { ReactNode, createElement } from 'react';

type Props = {
  children: ReactNode;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const SettingsTitle = ({ children, ...rest }: Props) => (
  createElement(
    "div",
    {
      className: `settings-title ${rest.className || ''}`,
      ...rest
    },
    children
  )
);
