import NLMThresholdSettings from "../../NLMThresholdSettings/Views/NLMThresholdSettings";
import HyperFocus from "../../HyperFocus/Views/HyperFocus";
import { useTranslation } from "react-i18next";
import { TodayMarkerContext } from "../../../layouts/PageLayout";
import { useContext, useEffect } from "react";
import { testIsSupportedWidth } from "../../ContentNotSupported/Views/ContentNotSupported";

export interface Props {
  show: boolean;
  /**
   * Optional handle login
   */
  handleShowModal: () => void;
}

const AppSettings = ({ show, handleShowModal }: Props) => {
  const { t } = useTranslation();
  const { setMobileSizeRestriction } = useContext(TodayMarkerContext);

  useEffect(() => {
    setMobileSizeRestriction(show)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <>
      <HyperFocus
        handleShowModal={handleShowModal}
        title={t("appsettings.title")}
        show={show && testIsSupportedWidth()}
        className="app-settings__modal"
      >
        <div className="app-settings__modal">
          <div
            className="app-settings__sidebar"
            data-testid="app-settings__sidebar"
          >
            <div className="sidebar--top-level">
              <span>
                <i className="icon icon-project"></i>
                {t("appsettings.project_intelligence")}
              </span>
            </div>
            <div className="sidebar--second-level">
              <div className="sidebar--second-level-item is-active">
                <span> {t("appsettings.nlm_model")}</span>
              </div>
            </div>
          </div>
          <div className="app-settings__content scrollbar-visible">
            <NLMThresholdSettings />
          </div>
        </div>
      </HyperFocus>
    </>
  );
};

export default AppSettings;
