import { useEffect, useState } from "react";
import useAcquireToken from "../../AuthToken/Hooks/useAcquireToken";
import useBSTInsightsAPI from "../../../apiclients/api/bstinsightsapi";

const useNLMThresholdSettings = () => {
  const { getTokenSilently } = useAcquireToken();
  const { getGlobalThreshold, updateGlobalThreshold } = useBSTInsightsAPI();
  const [isloading, setIsLoading] = useState(true);

  const [rangeValues, setRangeValue] = useState({
    globalThresholdId: "1",
    textboxValue: "2.75",
    sliderValue: "2.75",
  });
  const rangeLimits = { minValue: 0, maxValue: 6 };

  const handleOnChange = (value: string) => {
    if (!value || !isNaN(+value)) {
      const numberValue = Number(value);

      if (
        numberValue >= 0 &&
        numberValue <= 6 &&
        value.match("^(\\d*\\.{0,1}\\d{0,2}$)")
      ) {
        handleUpdateGlobalThreshold(numberValue);
        setRangeValue({ ...rangeValues, textboxValue: value });
      }
    }
  };

  const handleOnBlur = () => {
    if (isNaN(parseFloat(rangeValues.textboxValue))) {
      return setRangeValue({
        ...rangeValues,
        textboxValue: rangeValues.sliderValue,
      });
    }

    handleUpdateGlobalThreshold(parseFloat(rangeValues.textboxValue));
    setRangeValue({
      ...rangeValues,
      textboxValue: Number(rangeValues.textboxValue).toFixed(2),
      sliderValue: Number(rangeValues.textboxValue).toFixed(2),
    });
  };

  const handleSliderOnChange = (value: number) => {
    setRangeValue({
      ...rangeValues,
      textboxValue: value.toFixed(2),
      sliderValue: value.toFixed(2),
    });
  };

  const handleUpEvent = (value: number) => {
    handleUpdateGlobalThreshold(value);
  };

  const handleKeyPressEvent = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Escape") {
      setRangeValue({ ...rangeValues, textboxValue: rangeValues.sliderValue });
    }
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  };

  const handleUpdateGlobalThreshold = async (globalThresholdValue: number) => {
    getTokenSilently().then((accessToken) => {
      if (accessToken) {
        const request = {
          profitabilityThresholdId: parseInt(rangeValues.globalThresholdId),
          nlmThreshold: globalThresholdValue,
          nlmTolerance: undefined,
        };
        updateGlobalThreshold(accessToken, request);
      }
    });
  };

  const fract =
    (Number(rangeValues.sliderValue) - rangeLimits.minValue) /
    (rangeLimits.maxValue - rangeLimits.minValue);
  const percentLeft = fract * 100;
  const fractFromCentre = (fract - 0.5) * 2;
  const adjustment = fractFromCentre * -35; // Half thumb width

  const tickMarksRange = (start: any, end: any) => {
    return Array(end - start + 1)
      .fill(0)
      .map((start, idx) => ({ value: start + idx }));
  };

  useEffect(() => {
    getTokenSilently().then((accessToken) => {
      if (accessToken) {
        getGlobalThreshold(accessToken).then((globalThresholdResults) => {
          if (globalThresholdResults) {
            setIsLoading(false);
            setRangeValue({
              globalThresholdId:
                globalThresholdResults.profitabilityThresholdId?.toString() ||
                "",
              textboxValue:
                globalThresholdResults.nlmThreshold?.toFixed(2) || "",
              sliderValue:
                globalThresholdResults.nlmThreshold?.toFixed(2) || "",
            });
          }
        });
      }
    });
  }, [getGlobalThreshold, getTokenSilently]);

  return {
    handleOnChange,
    handleOnBlur,
    handleSliderOnChange,
    handleKeyPressEvent,
    handleUpEvent,
    percentLeft,
    adjustment,
    tickMarksRange,
    rangeValues,
    rangeLimits,
    isloading,
  };
};

export default useNLMThresholdSettings;
