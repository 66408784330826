import { useEffect, useRef, useState } from "react";
import useAcquireToken from "../../AuthToken/Hooks/useAcquireToken";
import useBSTInsightsAPI from "../../../apiclients/api/bstinsightsapi";
import {
  ProjectDTO,
  ProjectThresholdDTO,
} from "../../../apiclients/bstinsights";
import { AutoComplete } from "@syncfusion/ej2-dropdowns";
import { GridComponent, IEditCell } from "@syncfusion/ej2-react-grids";

const useNLMProjectOverrides = (projects: ProjectDTO[] | undefined) => {
  const { getTokenSilently } = useAcquireToken();
  const { getProjectThresholds, mergeProjectThresholds } = useBSTInsightsAPI();
  const [showValidation, setShowValidation] = useState(false);
  const [projectOverrides, setProjectOverrides] = useState<
    ProjectThresholdDTO[]
  >([]);

  let projectIdValue = useRef<number>(0);
  const [grid, setGrid] = useState<GridComponent>();

  const editOptions = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
  };

  useEffect(() => {
    getTokenSilently().then((accessToken) => {
      if (accessToken) {
        getProjectThresholds(accessToken).then((projectOverridesResults) => {
          if (projectOverridesResults) {
            setProjectOverrides(projectOverridesResults);
          }
        });
      }
    });
  }, [getProjectThresholds, getTokenSilently]);

  /* istanbul ignore next */
  const createCustomerIDFn = () => {
    inpuEle = document.createElement("input");
    inpuEle.className = "";
    return inpuEle;
  };
  /* istanbul ignore next */
  const destroyCustomerIDFn = () => {
    autoCompleteIns.destroy();
  };

  type autoCompleteObject = {
    projectCode: string;
    projectId: string;
    projectName: string;
  };

  /* istanbul ignore next */
  const readCustomerIDFn = (args: any) => {
    const selectedProjectInfo = autoCompleteIns.getDataByValue(
      autoCompleteIns.value
    ) as unknown as autoCompleteObject;

    if (!autoCompleteIns.value) return;

    if (selectedProjectInfo) {
      const selectedProjectId = parseInt(selectedProjectInfo.projectId);
      projectIdValue.current = selectedProjectId;
    }

    return autoCompleteIns.value;
  };

  /* istanbul ignore next */
  const writeCustomerIDFn = async (args: any) => {
    const results = projects;
    const projectList = results?.map((project) => {
      return {
        projectId: project.projectId?.toString() || "",
        projectName: project.projectName || "",
        projectCode: project.projectCode || "",
      };
    });

    autoCompleteIns = new AutoComplete({
      allowCustom: false,
      showClearButton: false,
      cssClass: "auto-complete",
      text: args.rowData.projectId,
      value: args.rowData.projectName,
      dataSource: projectList,
      fields: { value: "projectName", text: "projectName" },
    });
    autoCompleteIns.appendTo(inpuEle);
  };
  let inpuEle: HTMLInputElement;
  let autoCompleteIns: AutoComplete;
  let columnClicked: number = -1;

  const daParams: IEditCell = {
    create: createCustomerIDFn,
    destroy: destroyCustomerIDFn,
    read: readCustomerIDFn,
    write: writeCustomerIDFn,
  };

  /* istanbul ignore next */
  const recordDoubleClick = (e: any) => {
    const clickedColumnIndex = e.cell.getAttribute("data-colindex");
    columnClicked = parseInt(clickedColumnIndex);
  };

  const actionComplete = (args: any) => {
    /* istanbul ignore next */
    if (args.action === "add") {
      const appSettingContent = document.getElementsByClassName(
        "app-settings__content"
      )[0];
      const econtent = document.getElementsByClassName("e-content")[0];

      appSettingContent.scrollTop = appSettingContent.scrollHeight;
      econtent.scrollTop = econtent.scrollHeight;

      return;
    }
    if (args.requestType === "beginEdit") {
      const fieldName = grid?.getColumnByIndex(columnClicked).field;
      args.form.elements[
        (grid?.element.getAttribute("id") || "") + fieldName
      ].focus();

      if (
        fieldName?.toLowerCase() === "projectname" &&
        args.rowData["projectId"] > 0
      ) {
        args.cancel = true;
        grid?.refresh();
      }
    }
    if (args.requestType === "save") {
      let newProjectOverride = projectOverrides;

      if (args.data.projectId) {
        if (args.data.nlmThreshold === undefined) {
          args.cancel = true;
          grid?.refresh();
          return;
        }

        if (args.previousData.projectName !== args.data.projectName) {
          args.cancel = true;
        }
      } else {
        if (!projectIdValue || args.data.nlmThreshold === undefined) {
          newProjectOverride.pop();
          grid?.deleteRow(args.row);
          args.cancel = true;
          return;
        }
        /* istanbul ignore next */
        if (
          projectOverrides.some(
            (item) => (item.projectId || 0) === projectIdValue.current
          )
        ) {
          newProjectOverride.pop();
          grid?.deleteRow(args.row);
          args.cancel = true;
          return;
        }
      }

      const projectId = parseInt(
        args.data.projectId ? args.data.projectId : projectIdValue.current
      );

      if (
        projectId &&
        args.data.nlmThreshold >= -999 &&
        args.data.nlmThreshold <= 999
      ) {
        newProjectOverride[args.rowIndex].projectId = projectId;
        newProjectOverride[args.rowIndex].projectName = args.data.projectName;
        newProjectOverride[args.rowIndex].nlmThreshold = args.data.nlmThreshold;

        const request: ProjectThresholdDTO = {
          projectId: projectId,
          projectName: args.data.projectName,
          nlmThreshold: args.data.nlmThreshold,
        };

        handleInsertUpdateProjectOverride(request);
        setProjectOverrides(newProjectOverride);
      } else {
        handleShowValidation();

        const rowIndex = parseInt(args.rowIndex);
        grid?.selectRow(rowIndex);
        columnClicked = 1;
        grid?.startEdit();
      }
    }
  };

  const handleAddNewBlankRow = () => {
    const hasBlankRow = projectOverrides.some((item) => {
      return item.projectId === undefined;
    });

    const newRow = {
      projectId: undefined,
      projectName: undefined,
      nlmThreshold: undefined,
    };

    if (!hasBlankRow) {
      setProjectOverrides((projectOverrides) =>
        projectOverrides.concat(newRow)
      );
      grid?.addRecord(newRow);
    }
  };

  const handleInsertUpdateProjectOverride = (request: ProjectThresholdDTO) => {
    getTokenSilently().then((accessToken) => {
      if (accessToken) {
        mergeProjectThresholds(accessToken, request);
      }
    });
  };

  const handleShowValidation = () => {
    setShowValidation(!showValidation);
  };

  return {
    projectOverrides,
    setProjectOverrides,
    handleInsertUpdateProjectOverride,
    handleAddNewBlankRow,
    projects,
    actionComplete,
    daParams,
    editOptions,
    setGrid,
    recordDoubleClick,
    showValidation,
    handleShowValidation,
  };
};
export default useNLMProjectOverrides;
