import { useTranslation } from "react-i18next";
import contentNotSupported from "../../assets/img/content-not-supported.svg";
import { Modal, ModalBody } from "react-bootstrap";

const ErrorScreen = () => {
  const { t } = useTranslation();
  return (
    <Modal
      className="error-modal"
      show={true}
      centered={true}
      data-testid="error-screen"
    >
      <ModalBody className="error-modal-body">
        <img src={contentNotSupported} alt="something went wrong" />
        <div className="error-modal-header">{t("error.header")}</div>
        <div className="error-modal-subheader">{t("error.subheader")}</div>
      </ModalBody>
    </Modal>
  );
};

export default ErrorScreen;
