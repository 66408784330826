type Props = {
  width: number | string;
  height: number | string;
  animate?: boolean;
};

const SkeletonBlock = ({ width, height, animate }: Props) => {
  return (
    <div
      className={`skeleton-block ${animate ? "animated" : ""}`}
      style={{
        width,
        height,
      }}
    />
  );
};
export default SkeletonBlock;
