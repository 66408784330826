import { useCallback, useState } from "react";
import { useMsal } from "@azure/msal-react";

type IdTokenClaims = {
  groups: string[];
};

const useAcquireToken = () => {
  const { instance } = useMsal();
  const [account] = useState(instance.getAllAccounts()[0]);

  const getTokenSilently = useCallback(async () => {
    const accessTokenRequest = {
      scopes: [process.env.REACT_APP_BST_INSIGHTS_SCOPES || ""],
      account: account,
    };

    const tokenResponse = await instance.acquireTokenSilent(accessTokenRequest);

    return tokenResponse.accessToken;
  }, [account, instance]);

  const hasSupportGroup = () => {
    const supportGroupId = process.env.REACT_APP_BST_SUPPORT_GROUP_ID || "";
    const idTokenClaims = account?.idTokenClaims as IdTokenClaims;

    return idTokenClaims.groups.includes(supportGroupId);
  };

  return { getTokenSilently, hasSupportGroup };
};
export default useAcquireToken;
