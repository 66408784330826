import { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { RenderDayCellEventArgs } from "@syncfusion/ej2-react-calendars";
import { addDays, isAfter, isBefore } from "date-fns";

import useBSTInsightsAPI from "../../../apiclients/api/bstinsightsapi";
import { Schedule } from "../../../apiclients/bstinsights";
import useAcquireToken from "../../../components/AuthToken/Hooks/useAcquireToken";
import { PipelineEnum } from "../../../utils/pipelines";

export const leadingZero = (value: number) => {
  return value.toString().length > 1 ? value : `0${value}`;
};

export const format = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${leadingZero(month)}-${leadingZero(day)}`;
};

const modelRunFrequencies = [
  {
    value: 1,
    label: "Weekly",
  },
];

const defaultRunFrequency = modelRunFrequencies[0].value;

export const useNLMModelRunDetails = () => {
  const isAuthenticated = useIsAuthenticated();
  const { getTokenSilently } = useAcquireToken();
  const { getScheduleInformation, scheduleModelRun } = useBSTInsightsAPI();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [runFrequency, setRunFrequency] = useState(defaultRunFrequency);
  const [schedulePayload, setSchedulePayload] = useState<
    Schedule | undefined
  >();
  const [showCalendar, setShowCalendar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    /* istanbul ignore next */
    const getSchedule = async () => {
      if (!isAuthenticated) {
        return;
      }
      const token = await getTokenSilently();
      if (!token) {
        return;
      }
      const result = await getScheduleInformation(
        token,
        PipelineEnum.ProjectNLMPrediction
      );

      if (result) {
        setSchedulePayload(result);
      }

      if (result?.startDate) {
        setStartDate(new Date(result.startDate));
      }
      setIsLoading(false);
    };
    getSchedule();
  }, [getScheduleInformation, getTokenSilently, isAuthenticated]);

  const handleStartDateChange = async (date: Date | null) => {
    setStartDate(date);

    const token = await getTokenSilently();
    if (!token) {
      return;
    }
    if (!date) {
      return;
    }
    const dayOfWeek = date.getDay(); // 0 to 6 (sun to sat)
    const startDate = `${format(date)}T00:00:00`;
    const newSchedule = {
      pipelineId: PipelineEnum.ProjectNLMPrediction,
      ...schedulePayload,
      dayOfWeek,
      startDate,
    };
    scheduleModelRun(token, newSchedule);
    setSchedulePayload(newSchedule);
  };

  /**
   * If the LastPredictionRunDate = Null then next Available date is tomorrow.
   * If the LastPredictionRunDate is not null then next available date is LastPredictionRunDate + 7 days.
   * No date is available prior to today.
   * All dates after the first date noted as available will also be available for selection.
   * Get starting available date for Calendar
   * @param schedulePayload Schedule | undefined
   * @returns Date
   */
  /* istanbul ignore next */
  const getAvailableDate = (schedulePayload: Schedule | undefined) => {
    if (schedulePayload?.lastRunDate) {
      const LRD = new Date(schedulePayload.lastRunDate);
      if (isAfter(addDays(LRD, 7), new Date())) {
        return addDays(new Date(schedulePayload.lastRunDate), 7)
      }
      if (isBefore(LRD, new Date())) {
        return new Date();
      }
      return addDays(new Date(schedulePayload.lastRunDate), 7)
    }
    return new Date();
  };
  /**
   * Determine which Calendar date should be disabled
   * @param event RenderDayCellEventArgs
   */
  /* istanbul ignore next */
  const disabledDate = (event: RenderDayCellEventArgs) => {
    const availableFrom = getAvailableDate(schedulePayload);
    const cellDate = event.date;

    if (cellDate && isBefore(cellDate, availableFrom)) {
      event.isDisabled = true;
    }
  };

  return {
    startDate,
    dateString: format(startDate || new Date()),
    runFrequency,
    showCalendar,
    defaultRunFrequency,
    modelRunFrequencies,
    setShowCalendar,
    setRunFrequency,
    handleStartDateChange,
    isLoading,
    disabledDate,
  };
};
