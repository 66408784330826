import { useContext } from "react";

import { BSTInsightsAppContext } from "../../../layouts/PageLayout";
import { UserInformationDTO } from "../../../apiclients/bstinsights";

type UserProfile = UserInformationDTO & {
  phone: string | null;
  organization: string | null;
  workLocation: string | null;
};

type ProfileSectionItem = {
  id: keyof UserProfile;
  show: boolean;
  icon: string;
};

/* istanbul ignore next */
const profileSectionItems: ProfileSectionItem[] = [
  // TODO remove show when all fields are needed
  {
    id: "organization",
    show: false,
    icon: "icon-company",
  },
  {
    id: "email",
    show: true,
    icon: "icon-email",
  },
  {
    id: "phone",
    show: false,
    icon: "icon-phone",
  },
  {
    id: "workLocation",
    show: false,
    icon: "icon-pin",
  },
];

export const useProfileSection = () => {
  const { UserInformation } = useContext(BSTInsightsAppContext);
  const initials = UserInformation.initials
    ? UserInformation.initials
    : UserInformation.userName
        ?.split(" ")
        .map((name) => name?.[0])
        .join(" ");
  return {
    userInfo: {
      ...UserInformation,
      initials,
      // TODO get data from API
      phone: null,
      organization: null,
      workLocation: null,
    },
    profileSectionItems,
  };
};
