import useBanner from "../Hooks/useBanner";

const Banner = () => {
  const { showBanner } = useBanner();
  return (
    <div
      className={`banner ${showBanner ? "banner-show" : "banner-hide"}`}
      data-testid="banner"
    >
      <div className="banner-div" data-testid="banner-div">
        <span
          className="banner-alert-icon"
          data-testid="banner-alert-icon"
          aria-hidden="true"
          title="alert"
        >
          <i className="icon icon-alert"></i>
        </span>
        <span className="banner-label" data-testid="banner-label">
          We are currently experiencing some issues with the data pipeline. Our
          team is working on the issue.
        </span>
      </div>
    </div>
  );
};

export default Banner;
