import React, { ReactNode, useContext, useEffect } from "react";
import { BSTInsightsAppContext } from "../layouts/PageLayout";
import { Navigate } from "react-router-dom";
import { RolesEnum } from "./roles";
import useBSTInsightsAPI from "../apiclients/api/bstinsightsapi";
import useAcquireToken from "../components/AuthToken/Hooks/useAcquireToken";
import { useMsal } from "@azure/msal-react";

type RolesAuthRouteType = {
  children: ReactNode;
  roles: string[];
  route: string;
};

const RolesAuthRoute = ({ children, roles, route }: RolesAuthRouteType) => {
  const { UserRoles, setUserRoles } = useContext(BSTInsightsAppContext);
  const { getUserRoles } = useBSTInsightsAPI();
  const { getTokenSilently, hasSupportGroup } = useAcquireToken();
  const { instance } = useMsal();
  let hasOnlyAccountOwner: boolean = false;
  let hasRole: boolean = false;

  useEffect(() => {
    getTokenSilently().then((accessToken) => {
      if (accessToken) {
        const activeAccount = instance.getActiveAccount();
        getUserRoles(
          accessToken,
          activeAccount?.localAccountId || "",
          hasSupportGroup()
        ).then((userRolesResults) => {
          if (userRolesResults) {
            setUserRoles(userRolesResults);
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTokenSilently, getUserRoles, instance, setUserRoles]);

  if (UserRoles.length > 0) {
    hasOnlyAccountOwner =
      UserRoles.length === 1 &&
      UserRoles.some(
        (userRole) => userRole.roleId === RolesEnum["Account Owner"]
      );
    hasRole = UserRoles.some((userRole) =>
      roles.includes(userRole.roleName || "")
    );

    if (route === "Home") {
      if (hasOnlyAccountOwner) {
        /* istanbul ignore next */
        return <Navigate to="/UserManagement" />;
      }
      return <React.Fragment>{children}</React.Fragment>;
    }

    if (route === "UserManagement") {
      if (hasRole) {
        return <React.Fragment>{children}</React.Fragment>;
      }
      /* istanbul ignore next */
      return <Navigate to="/" />;
    }
  }

  /* istanbul ignore next */
  return null;
};
export default RolesAuthRoute;
