export const DISPLAY_MENU_ITEM = "my-settings-display-menu";
export const PROFILE_MENU_ITEM = "my-settings-profile-menu";

export type MenuItemID = typeof DISPLAY_MENU_ITEM | typeof PROFILE_MENU_ITEM;

export type MenuItem = {
  id: MenuItemID;
  icon: string;
  title: string;
};
export const menuItems: MenuItem[] = [
  {
    id: PROFILE_MENU_ITEM,
    icon: "icon-profile",
    title: "Profile",
  },
];
