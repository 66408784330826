import React, { useState, useEffect, useRef } from "react";
import { MultiSelectComponent, CheckBoxSelection, Inject } from '@syncfusion/ej2-react-dropdowns';

import { enableRipple } from '@syncfusion/ej2-base';
enableRipple(true);


/**
 *
 * The MultiSelect allows the user to pick a values from the predefined list of values.
 * @param dataSource - Accepts the list items either through local or remote service and binds it to the MultiSelect component. It can be an array of JSON Objects
 * @param fields -The fields property maps the columns of the data table and binds the data to the component.
 * @param value - Selects the list item which maps the data value field in the component.
 * @param className - Defines a Classname to component
 * @param placeholder - Gets or sets the placeholder in the component to display the given information in input when no item selected.
 * @param popupHeight - Gets or sets the height of the popup list. By default it renders based on its list item.
 * @param onChange - Fires each time when selection changes happened in list items after model and input value get affected.
 *
 * @remarks 
 * Syncfusion React Multi-select documentation {@link https://ej2.syncfusion.com/react/documentation/multi-select/getting-started/}.
 *
 **/

export interface Props {
    dataSource?: any[],
    fields?: object,
    value?: number[],
    className?: string,
    placeholder?: string,
    popupHeight?: string,
    disabled?: boolean,
    onChange?: (event: any) => void,
}

const MultiselectDropdown = ({ dataSource, popupHeight, className, placeholder, value, fields, onChange, disabled }: Props) => {
    let mulObj = useRef<any>();
 
    const onChangeVal = (e: any) => {
        if (typeof onChange === 'function') {
            onChange(e);
        }
    }

    function onChangeItems(args: any) {
        if (mulObj.current) {
            if (mulObj.current.viewWrapper) {
                mulObj.current.viewWrapper.style.display = 'none';
            }
            mulObj.current.chipCollectionWrapper.style.display = 'block';
            let inputPos = mulObj.current.overAllWrapper.getBoundingClientRect();
            let popupPos =
                mulObj.current.popupWrapper && mulObj.current.popupWrapper.getBoundingClientRect();
            if (inputPos && popupPos && inputPos.top + inputPos.height > popupPos.top) {
                mulObj.current.popupWrapper.style.top = inputPos.top + inputPos.height + 'px';
            }
        }

        onChangeVal(args.value)
    }

    function onSelect(args: any) {
        mulObj.current.addChip(args.itemData.roleName, args.itemData.roleId, args.e);
    }

    function onCreate(args: any) {
        mulObj.current.chipCollectionWrapper = mulObj.current.createElement('span', {
            className: 'e-chips-collection',
            styles: 'display:none',
        });
        mulObj.current.componentWrapper.appendChild(mulObj.current.chipCollectionWrapper);
    }


    function onFocus(args: any) {
        if (mulObj.current && mulObj.current.viewWrapper) {
            mulObj.current.viewWrapper.style.display = 'none';
        }
    }


    return (
        <React.Fragment>
            <MultiSelectComponent
                ref={mulObj}
                change={onChangeItems}
                select={onSelect}
                created={onCreate}
                focus={onFocus}
                dataSource={dataSource}
                fields={fields}
                changeOnBlur={false}
                placeholder={placeholder}
                mode="CheckBox"
                floatLabelType="Auto"
                showSelectAll={false}
                showDropDownIcon={true}
                allowFiltering={false}
                allowCustomValue={true}
                popupHeight={popupHeight}
                value={value}
                delimiterChar=" "
                className={className}
                enabled={!disabled}
            >
                <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
        </React.Fragment>
    );
};

export default MultiselectDropdown;
