import { ChangeEvent } from 'react';
import { EmitType } from '@syncfusion/ej2-base';
import { CalendarComponent, RenderDayCellEventArgs } from '@syncfusion/ej2-react-calendars';

type Props = {
  value: Date | null;
  onChange: (value: Date | null) => void;
  dateString: string;
  showCalendar: boolean;
  setShowCalendar: (value: boolean) => void;
  disabledDate?: EmitType<RenderDayCellEventArgs>;
}

export const DateInput = ({
  value,
  onChange,
  dateString,
  showCalendar,
  disabledDate,
  setShowCalendar
}: Props) => {
  return (
    <div className="input-control input-control__date">
      <div
        className="input-control__date-input"
        data-testid="dateinput-control"
        onClick={() => setShowCalendar(!showCalendar)}
      >
        {dateString}
        <i className="icon icon-calendar" />
      </div>
      {showCalendar && (
        <CalendarComponent
          dayHeaderFormat="Narrow"
          className="input-control__calendar"
          data-testid="dateinput-calendar"
          value={value || new Date()}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            /* istanbul ignore next */
            const value = (event.target.value as unknown as Date) || new Date()
            onChange(value);
            setShowCalendar(false);
          }}
          renderDayCell={disabledDate}
        />
      )}
    </div>
  );
};
