import React, { ReactNode, createElement } from 'react';

type Props = {
  children: ReactNode;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const SettingsContainer = ({ children, className, ...rest }: Props) => (
  createElement(
    "div",
    {
      className: `settings-container ${className || ''}`,
      ...rest
    },
    children
  )
);
