import React from "react";
import { useTranslation } from "react-i18next";

import { DateInput } from "../Components/DateInput";
import { SelectControl } from "../Components/SelectControl";
import { SettingsTitle } from "./../Components/SettingsTitle";
import { SettingsContainer } from "./../Components/SettingsContainer";
import { useNLMModelRunDetails } from "../Hooks/useNLMModelRunDetails";
import SkeletonBlock from "../../Skeletons/SkeletonBlock";

const FormLabel = ({ label }: { label: string }) => (
  <div className="model-run-details__form-label">{label}</div>
);

export const NLMModelRunDetails = () => {
  const { t } = useTranslation();
  const {
    startDate,
    dateString,
    runFrequency,
    showCalendar,
    defaultRunFrequency,
    modelRunFrequencies,
    setRunFrequency,
    setShowCalendar,
    handleStartDateChange,
    isLoading,
    disabledDate
  } = useNLMModelRunDetails();
  return (
    <SettingsContainer className="model-run-details">
      <SettingsTitle>{t("nlmsettings.modelRunDetails")}</SettingsTitle>
      {isLoading ? (
        <div className="model-run-details__row">
          <div className="model-run-details__skeleton">
            <SkeletonBlock width={"40%"} height={9} />
            <SkeletonBlock width={"100%"} height={14} />
          </div>
          <div className="model-run-details__skeleton">
            <SkeletonBlock width={"40%"} height={9} />
            <SkeletonBlock width={"100%"} height={14} />
          </div>
          <div className="model-run-details__skeleton"></div>
        </div>
      ) : (
        <div className="model-run-details__row">
          <div className="model-run-details__form-control">
            <FormLabel label={t("nlmsettings.modelRunFrequency")} />
            <SelectControl
              disabled
              value={runFrequency}
              options={modelRunFrequencies}
              onChange={(value) =>
                setRunFrequency((value as number) || defaultRunFrequency)
              }
            />
          </div>
          <div className="model-run-details__form-control">
            <FormLabel label={t("nlmsettings.startDate")} />
            <DateInput
              value={startDate}
              dateString={dateString}
              onChange={handleStartDateChange}
              showCalendar={showCalendar}
              disabledDate={disabledDate}
              setShowCalendar={setShowCalendar}
            />
          </div>
          <div
            className="model-run-details__form-control"
            style={{ opacity: 0 }}
          >
            <FormLabel label={t("nlmsettings.startTime")} />
          </div>
        </div>
      )}
    </SettingsContainer>
  );
};
