/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserPreferencesDTO } from "../models/UserPreferencesDTO";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class UserPreferencesService {
  /**
   * @param userName
   * @param widgetName
   * @returns UserPreferencesDTO
   * @throws ApiError
   */
  public static userPreferencesGetUserPreferences(
    userName?: string | null,
    widgetName?: string | null
  ): CancelablePromise<UserPreferencesDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/UserPreferences/Preferences",
      query: {
        userName: userName,
        widgetName: widgetName,
      },
    });
  }

  /**
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static userPreferencesUpsertUserPreferences(
    requestBody: UserPreferencesDTO
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/UserPreferences/Upsert",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static userPreferencesDeleteUserPreferences(
    requestBody: UserPreferencesDTO
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/UserPreferences/Delete",
      body: requestBody,
      mediaType: "application/json",
    });
  }
}
