import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-http-backend";


/**
  * 
  * i18n configuration file 
  *
  * @remarks
  * This sets the configuration for the i18n to use the backend configuration to configure where the resource file is
  * located. It also sets the language detection for the application. The resource files for the languages are written in
  * a json object format. 
  **/

i18n
.use(initReactI18next)
.use(Backend)
.use(LanguageDetector)
.init({
  debug: false,
  detection: {
    order:['querystring','navigator'],
    lookupQuerystring:"lng"
  },
  interpolation: { 
    format: (value, rawformat, lng):string => {
      const [format,currency] = rawformat!.split(",")?.map((v) => v.trim());

      if (format === "number") {
        return new Intl.NumberFormat(lng).format(value);
      }
      if (format === "date") {
        return new Intl.DateTimeFormat(lng).format(value);
      }
      if (format === "currency") {
        return new Intl.NumberFormat(lng, {
          style: "currency",
          currency: currency,
        }).format(value);
      }
      return value;
    },
    escapeValue:false
  },
  fallbackLng: "en"
});