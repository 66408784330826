
const RowEdit = ({ row, name, value, onUpdate }: any) => {
    const onKeyDown = (e: any) => {
        // Stop default behaivior to avoid scroll issues
        e.stopPropagation()
        // if it is "enter" key then save changes
        if(e.keyCode === 13) e.target.closest('.e-rowcell')?.focus()
    }
    const saveChanges = (e: any) => {
        e.target.setAttribute('readOnly', 'readOnly')
        e.target.closest('.e-rowcell')?.classList?.remove('e-inputbox', 'e-editedbatchcell')
        const newValue = e.target.value;
        const defaultValue = e.target.dataset.defaultvalue;
        
        /* Sometimes SyncFusion rerender the componente when virtualization is anabled
            It makes the input lost the focus incorrectly
            So the Timeout will check if the input is still on them DOM after 0.3s
            if it is not in the DOM it means SyncFusion rerender it
            then we get the new rendered input field by his ID and focus it again and update the value
        */
        setTimeout(() => {
            if(!e.target.parentNode) {
                const el: any = document.getElementById(e.target.id)
                if(el) setFocus(el, defaultValue, newValue)
            } else {
                if (newValue && newValue.trim() !== '' && newValue !== defaultValue && typeof onUpdate === 'function') {
                    onUpdate(newValue, row, e)
                } else {
                    e.target.value = defaultValue;
                }
            }
        }, 300)
    }

    const inputOnDoubleClick = (e: any) => {
        const currentValue = e.target.value;
        setFocus(e.target, currentValue, currentValue)
        
    }

    const setFocus = (el: any, currentValue: string, value: string) => {
        el.value = ""
        el.dataset.defaultvalue = currentValue
        el.closest('.e-rowcell')?.classList?.add('e-inputbox', 'e-editedbatchcell')
        // Using setTimeout to help minimize SyncFusion's weird virtualization issues
        setTimeout(() => el.value = value, 100)
        setTimeout(() => {
            el.removeAttribute('readOnly')
            el.focus();
        }, 300)
    }
    return <input data-testid="row-input" id={`input-${name}${row.uid}`} readOnly={true} data-defaultvalue={value} className="inline-grid-edit" onKeyDown={onKeyDown} defaultValue={value} onDoubleClick={inputOnDoubleClick} onBlur={saveChanges} />;
}

export default RowEdit;