import { useMsal } from "@azure/msal-react";

const useSignoutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({});
  };

  return { handleLogout };
};

export default useSignoutButton;
