import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { L10n } from "@syncfusion/ej2-base";

import useNLMProjectOverrides from "../Hooks/useNLMProjectOverrides";
import { useTranslation } from "react-i18next";
import { ProjectDTO } from "../../../apiclients/bstinsights";
import { Modal, ModalBody } from "react-bootstrap";

interface Props {
  projects: ProjectDTO[] | undefined;
}
const NLMProjectOverrides = ({ projects }: Props) => {
  const {
    projectOverrides,
    handleAddNewBlankRow,
    actionComplete,
    daParams,
    editOptions,
    setGrid,
    recordDoubleClick,
    showValidation,
    handleShowValidation,
  } = useNLMProjectOverrides(projects);

  const { t } = useTranslation();

  L10n.load({
    "en-US": {
      dropdowns: {
        noRecordsTemplate: t("dropdowns.noRecordsTemplate"),
      },
    },
  });

  return (
    <div className="nlm_project_overrides-settings">
      <div className="title" data-testid="nlm_project_overrides-title">
        <span>{t("nlmsettings.projectsNLMOverrides")}</span>
        {projectOverrides.length > 0 && (
          <button
            className="icon-button icon-button--secondary icon-button--lg icon-button--ghost"
            data-testid="project_override-add"
            onClick={handleAddNewBlankRow}
          >
            <i className="icon icon-add"></i>
          </button>
        )}
      </div>
      {projectOverrides.length === 0 && (
        <div className="no_project_overrides">
          <span>
            You don't have any projects with NLM Overrides yet. Would you like
            to add one?
          </span>
          <button
            className="button button--primary"
            aria-label="Add Project"
            data-testid="nlm_project_override-add-projects"
            onClick={handleAddNewBlankRow}
          >
            Add Project
            <i className="icon icon-add"></i>
          </button>
        </div>
      )}
      {projectOverrides.length > 0 && (
        <div>
          <GridComponent
            data-testid="project-override-grid"
            dataSource={projectOverrides}
            gridLines="Both"
            loadingIndicator={{ indicatorType: "Shimmer" }}
            allowSorting={true}
            editSettings={editOptions}
            rowHeight={56}
            cssClass="project_grid"
            locale="en-US"
            sortSettings={{
              columns: [{ field: "projectName", direction: "Ascending" }],
            }}
            selectionSettings={{ mode: "Both" }}
            actionComplete={actionComplete}
            recordDoubleClick={recordDoubleClick}
            ref={(g) => setGrid(g || undefined)}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="projectName"
                headerText={t(
                  "nlmsettings.project_override_header_projectName"
                )}
                width="42%"
                foreignKeyField="projectId"
                type="string"
                edit={daParams}
              />
              <ColumnDirective
                field="nlmThreshold"
                headerText={t(
                  "nlmsettings.project_override_header_nlmthreshold"
                )}
                width="25%"
                type="decimal"
                textAlign="Right"
              />
              <ColumnDirective field="" headerText="" allowEditing={false} />
            </ColumnsDirective>
            <Inject services={[Edit, Sort]} />
          </GridComponent>
          <button
            className="project-override-button"
            data-testid="project_override-footer-add"
            onClick={handleAddNewBlankRow}
          >
            <div className="footer">
              <span className="footer-message" data-testid="footer-message">
                {t("nlmsettings.project_override_addrow_text")}
              </span>
            </div>
          </button>
        </div>
      )}
      <Modal
        className="modal-validation"
        backdrop="static"
        show={showValidation}
      >
        <ModalBody>
          <div className="modal-area">
            <span className="modal-header-text">
              {t("nlmsettings.validation_header")}
            </span>
            <span className="modal-subheader-text">
              {t("nlmsettings.validation_subheader")}
            </span>
          </div>
          <button className="modal-button" onClick={handleShowValidation}>
            {t("nlmsettings.Validation_button_text")}
          </button>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default NLMProjectOverrides;
