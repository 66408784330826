/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Schedule } from "../models/Schedule";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ScheduleService {
  /**
   * @param pipelineId
   * @returns Schedule
   * @throws ApiError
   */
  public static scheduleGetScheduleInformation(
    pipelineId?: number
  ): CancelablePromise<Schedule> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Schedule/GetScheduleInformation",
      query: {
        pipelineId: pipelineId,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static scheduleScheduleModelRun(
    requestBody: Schedule
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Schedule/ScheduleModelRun",
      body: requestBody,
      mediaType: "application/json",
    });
  }
}
