import SkeletonBlock from "../../Skeletons/SkeletonBlock";

const NLMProjectSkeleton = () => {
  return (
    <div className="nlm_project_overrides-settings">
      <div className="title" data-testid="nlm_project_overrides-title-skeleton">
        <span>Projects With NLM Overrides</span>
        <button
          className="icon-button icon-button--secondary icon-button--lg icon-button--ghost"
          data-testid="project_override-add"
        >
          <i className="icon icon-add"></i>
        </button>
      </div>

      <table>
        <thead>
          <tr>
            <th style={{ width: "42%" }}>
              <SkeletonBlock width={41} height={16} />
            </th>
            <th style={{ width: "25%" }}>
              <SkeletonBlock width={41} height={16} />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: "42%" }}>
              <SkeletonBlock width={157} height={14} />
            </td>
            <td style={{ width: "25%" }}>
              <SkeletonBlock width={157} height={14} />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <SkeletonBlock width={157} height={14} />
            </td>
            <td>
              <SkeletonBlock width={157} height={14} />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <SkeletonBlock width={157} height={14} />
            </td>
            <td>
              <SkeletonBlock width={157} height={14} />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <SkeletonBlock width={157} height={14} />
            </td>
            <td>
              <SkeletonBlock width={157} height={14} />
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default NLMProjectSkeleton;
