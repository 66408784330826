import { FormEventHandler } from "react";

export const isValidEmail = (email : string) => {
  // eslint-disable-next-line no-useless-escape
  const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(String(email).toLowerCase());
}

export const validateNumericOnInput: FormEventHandler<HTMLInputElement> = (event) => {
  const target = event.target as HTMLInputElement;
  // "-" OR "+" with digits AND/OR "." AND OR digits
  // eslint-disable-next-line no-useless-escape
  target.value = target.value.match(/(^(\-{1}|\+{1})?(\d+)?(\.)?(\d+)?)/g)?.shift() || "";
}