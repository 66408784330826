import { useEffect, useState } from "react";

const Splash = () => {
  const [showSplash, setShowSplash] = useState(true);

  /* istanbul ignore next */
  useEffect(() => {
    performance.mark("Render.Splash");
    setTimeout(() => {
      performance.mark("Remove.Splash");
      setShowSplash(false);
    }, 3000);
  }, []);

  return (
    <div>
      {showSplash && (
        <div
          id="splash"
          data-testid="splash"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "#ffffff",
            zIndex: 5000,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              zIndex: 5000,
            }}
          >
            <svg
              width="444"
              height="444"
              viewBox="0 0 444 444"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                width: "27vw",
                minWidth: "280px",
                maxWidth: "444px",
                zIndex: 5000,
              }}
            >
              <g clipPath="url(#clip0_2783_627939)">
                <circle cx="222" cy="222" r="222" fill="white" />
                <g clipPath="url(#clip1_2783_627939)">
                  <path
                    d="M325.172 197.541C333.475 186.653 339.01 176.731 342.292 167.84C330.707 167.454 316.419 169.258 300.264 173.059C289.322 187.233 267.311 202.437 230.818 217.706C395.97 218.866 337.465 364.598 142.578 332.514C293.377 399.259 473.59 247.858 325.172 197.541Z"
                    fill="#1274E6"
                  />
                  <path
                    d="M114.58 270.729C108.594 276.334 102.995 282.39 97.9102 288.897C121.917 308.482 155.449 320.788 190.462 320.788C210.092 320.852 229.465 316.858 247.422 308.998C234.807 313.057 221.613 315.183 208.29 315.183C168.708 315.183 133.953 297.401 114.58 270.729Z"
                    fill="#1274E6"
                  />
                  <path
                    d="M87.2273 317.31C75.0629 293.215 128.74 241.416 207.133 201.665C285.525 161.914 358.962 149.222 371.19 173.317C373.636 178.085 373.379 183.948 370.997 190.584C384.642 173.768 390.113 159.015 384.9 148.771C379.686 138.527 364.69 134.275 343.193 135.241C322.147 83.7649 184.864 89.1767 151.074 107.796C217.366 90.7874 292.734 108.633 306.636 140.653C274.841 147.869 236.546 161.914 197.028 181.95C158.862 201.278 125.78 223.054 101.387 243.864C99.3916 236.777 98.3618 229.433 98.3618 222.088C98.3618 170.611 147.598 128.863 208.356 128.863C223.931 128.863 239.378 131.698 253.924 137.303C234.229 127.317 212.475 122.163 190.463 122.292C123.849 122.292 62.3837 166.746 62.3837 221.573C62.3837 237.937 67.8544 253.399 77.3799 267.057C60.6459 285.934 53.4374 302.621 59.1656 313.895C64.3789 324.204 79.7613 328.52 101.644 327.36C94.6932 325.428 89.673 322.142 87.2273 317.31Z"
                    fill="#1274E6"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2783_627939">
                  <rect width="444" height="444" fill="white" />
                </clipPath>
                <clipPath id="clip1_2783_627939">
                  <rect
                    width="329.531"
                    height="254.677"
                    fill="white"
                    transform="translate(57.2344 94.5234)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      )}
      {!showSplash && <div data-testid="removed-splash-screen" />}
    </div>
  );
};

export default Splash;
