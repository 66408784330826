type Props = {
  onCloseHandler?: () => void;
}

export function HyperFocusCloseBtn({ onCloseHandler }: Props) {
  return (
    <button
      className="icon-button icon-button--ghost icon-button--md"
      data-testid="modal-close"
      onClick={onCloseHandler}
    >
      <span
        className="global-utilities__button__icon"
        aria-hidden="true"
        title="Close"
      >
        <i className="icon icon-close"></i>
      </span>
    </button>
  );
}