import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import contentNotSupported from "../../../assets/img/content-not-supported.svg";

/**
 * Interface for ContentNotSupported
 * @param isSupportedCallBack - handles the screen size change
 */
interface ContentNotSupportedProps {
  isSupportedCallBack: (isSupported: boolean) => void;
  mobileSizeRestriction: boolean
}

export const testIsSupportedWidth = () => {
    if (typeof window === 'undefined') return true;
    return window.innerWidth >= 920;
}

/**
 * ContentNotSupported
 *
 * Is displayed when screen size is not supported
 *
 * @exports ContentNotSupported
 * @returns a jsx element.
 */
const ContentNotSupported = ({ isSupportedCallBack, mobileSizeRestriction } : ContentNotSupportedProps) => {
  const { t } = useTranslation();
  const [isSupportedScreenSize, setIsSupportedScreenSize] = useState(testIsSupportedWidth);
  useEffect(() => {
    if (typeof window === 'undefined')  return;

    const autoResize = () => {
      const isSupportedWidth = testIsSupportedWidth()
      setIsSupportedScreenSize(isSupportedWidth);
      if (typeof isSupportedCallBack === 'function') isSupportedCallBack(isSupportedWidth)
    }

    window.addEventListener('resize', autoResize);
    autoResize();
    return () => window.removeEventListener('resize', autoResize);
  }, [])

  if(isSupportedScreenSize || !mobileSizeRestriction) return null

  return (
    <div data-testid="content-not-supported" className="content-not-supported">
      <div className="content-not-supported-box">
        <img src={contentNotSupported} alt="content not supported" />
        <h2 className="heading-xl">{t("contentNotSupported_title")}</h2>
        <p className="lead">{t("contentNotSupported_subtitle")}</p>
      </div>
    </div>
  )
};
export default ContentNotSupported;
