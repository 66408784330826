import { useEffect, useRef, useState } from "react";
import { Column } from "../../components/Grid/Views/Grid";
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import useAcquireToken from "../../components/AuthToken/Hooks/useAcquireToken";
import { useIsAuthenticated } from "@azure/msal-react";
import useBSTInsightsAPI from "../../apiclients/api/bstinsightsapi";
import { CreateUserRequest, RoleDTO, UpdateUserRoleRequest, UserDTO } from "../../apiclients/bstinsights";
import { useTranslation } from "react-i18next";
import { GridComponent } from "@syncfusion/ej2-react-grids";
import RowEdit from "../../components/Grid/Views/RowEdit";


/**
*
* Returns necessary props to load the page
*
* ```
{
  dataSource,
  columns,
  isLoading
}
* ```
*
**/
const useUserManagement = (isHyperFocus?: boolean, refreshGridData?: Function, updateRolesOnGrid?: Function, setUpdateTotals?: Function, gridInstance?: React.MutableRefObject<GridComponent | null>) => {

  const [isLoading, setIsLoading] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false)
  let elementWithScroll: HTMLElement | null;
  let eGridcontent: HTMLElement | null;
  let elementTitle: HTMLElement | null;
  let elementGrid: HTMLElement | null;

  const { getUsers, getRoles, inviteUsersWithRoles, updateUser, addUserRole, removeUserRole, getBackgroundProcessStatus } = useBSTInsightsAPI();
  const isAuthenticated = useIsAuthenticated();
  const { getTokenSilently } = useAcquireToken();
  const [dataSource, setDataSource] = useState([])
  const [columns, setColumns] = useState<Column[]>([])
  const [selected, setSelected] = useState<Array<any>>([])
  const [filterColumns, setFilterColumns] = useState<Array<any>>([])
  const [modalInvitationsVisible, setModalInvitationsVisible] = useState<boolean>(false);
  const [swicthChecked, setSwitchChecked] = useState<boolean>(true);
  const invitesCountFef = useRef<number>();
  const { t } = useTranslation();
 const checkboxSelectAllRef = useRef<CheckBoxComponent>(null);

  const updateRecord = async (name: string | undefined, email: string | undefined, id: number) => {
    const request: CreateUserRequest = {
      displayName: name,
      email: email
    };
    setIsLoading(true)
    if(typeof refreshGridData === 'function') refreshGridData(name, email, id);
    const token: string = await getTokenSilently();
    if (token) {
      try {
        const result = await updateUser(token, id, request)
        if(result === 'error') await loadData()
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)}
      }
  }

  const updateName = async (value: string, row: any) => {
    await updateRecord(value, undefined, row.uid)
  }
  const updateEmail = (value: string, row: any) => {
    updateRecord(undefined, value, row.uid)
  }

  const isSelectable = (groups: any[] = [], accountStatusName: string) => 
    !(['Sending Invitation', 'Invitation Sent', 'Invitation Accepted'].includes(accountStatusName) ||  groups.length === 0)

  const getSelectable : any = () => getGridDatasource().filter((p: any) => isSelectable(p.groups, p.accountStatusName)) || [];

  const getGridDatasource : any = () => gridInstance?.current?.dataSource || [];

  const getSelectedRecords = () =>  getGridDatasource().filter((p: any) => p.isSelected) || [];

  const getRow = (id: number) => getGridDatasource().find((p: any) => p.uid === id);

  const clearSelectedRows = () => {
    setSelectedRows([])
    getGridDatasource().filter((p: any) => p.isSelected).forEach((p: any) => p.isSelected = false);
    if (gridInstance?.current?.dataSource)
      gridInstance.current.dataSource = [...(gridInstance.current.dataSource as object[])]
  }
  
  const selectAllRows = () => {
    const selectable = getSelectable();
    setSelectedRows(selectable)
    selectable.forEach((p: any) => p.isSelected = true);

    if (gridInstance?.current?.dataSource)
      gridInstance.current.dataSource = [...(gridInstance.current.dataSource as object[])]
  }

  const toggleRowSelection = (e: any, props: any) => {
    const row = getRow(props.uid);
    if (row) row.isSelected = e.checked;
    setSelectedRows([...(getSelectedRecords() || [])])
    checkSelectionAllState()
  }

  const templateAvatar = (props: any) => {
    let initials = ''
    let initialsSplit = props?.name.split(' ');
    initials = initialsSplit && initialsSplit.length > 1 ? initialsSplit[0].charAt(0) + initialsSplit[1].charAt(0) : props?.name[0]?.charAt(0) + props?.name[1]?.charAt(0)

    return (
      <div>
        <CheckBoxComponent
          change={(e: any) => toggleRowSelection(e, props)}
          checked={props.isSelected}
          data-testid={`grid-select-${props.uid}`}
        />
        <div className="avatar">
          <span className="label-sm">{initials}</span>
        </div>
      </div>
    )
  }

  const toggleRowSelectionAll = (e: any) => {
    if (e.checked)
      selectAllRows()
    else
      clearSelectedRows()
    checkSelectionAllState()
  }

  const checkSelectionAllState = () => {
    if (!checkboxSelectAllRef?.current)
      return;

    const selected = getSelectedRecords().length
    const selectable = getSelectable().length

    checkboxSelectAllRef.current.indeterminate = false
    if (selected === 0)
      checkboxSelectAllRef.current.checked = false;
    else if (selected < selectable)
      checkboxSelectAllRef.current.indeterminate = true
    else
      checkboxSelectAllRef.current.checked = true;
  }

  const headerTemplate = () => {
    return (<div className="header-checkbox">
      <CheckBoxComponent
        ref={checkboxSelectAllRef}
        change={toggleRowSelectionAll}
      />
    </div>)
  }

  interface Anything {
    [key: string]: any;
  }

  const fixedColumns: Column[] = [
    {
      field: 'uid',
      isPrimaryKey: true,
      visible: false,
      allowEditing: false,
    },
    {
      field: 'isSelected',
      // type: 'checkbox',
      headerText: ' ',
      width: '70px',
      textAlign: 'Center',
      template: templateAvatar,
      headerTemplate: headerTemplate,
      allowEditing: false,
      allowResizing: false,
    },
    {
      field: 'name',
      headerText: t("userManagementPage.gridColumns.name"),
      width: '237px',
      allowEditing: true,
      allowResizing: true,
      template: (e:any) => <RowEdit row={e} value={e.name} name="name" onUpdate={updateName} />
    },
    {
      field: 'accountStatusName',
      headerText: t("userManagementPage.gridColumns.accessStatus"),
      width: '230px',
      allowEditing: false,
      allowResizing: true,
    },
    {
      field: 'email',
      headerText: t("userManagementPage.gridColumns.email"),
      width: '273px',
      allowEditing: true,
      template: (e:any) => <RowEdit row={e} value={e.email} name="email" onUpdate={updateEmail} />,
      allowResizing: true,
    },{
      field: 'employeeTitle',
      headerText: t("userManagementPage.gridColumns.employeeTitle"),
      width: '230px',
      allowResizing: true,
    },
    {
      field: 'lastLoggedInDateTime',
      headerText: t("userManagementPage.gridColumns.lastLoggedInDateTime"),
      width: '230px',
      allowEditing: false,
      allowResizing: true,
      template: (e:any) => <span>{e.lastLoggedInDateTimeString}</span>
    }
  ];

  const extraColumns = [{
      field: 'employeeHomeOrganizationName',
      headerText: t("userManagementPage.gridColumns.organization"),
      allowResizing: true,
      width: '230px',
    },
    {
      field: 'employeeHomeCompanyName',
      headerText: t("userManagementPage.gridColumns.company"),
      allowResizing: true,
      width: '230px',
    }]

  const onSendInvites = async (grid: GridComponent | null) => {
    const data: Array<CreateUserRequest> = selectedRows.map(item => {
      const row = selected.find(e => e.email === item.email);
      return {
        email: item.email,
        roleIds: row ? row.roles : item.groups.map((p: any) => parseInt(p.replace("roleGroup", ""), 10))
      }
    });

    setIsLoading(true)
    if (isAuthenticated) {
      const token: string = await getTokenSilently();
      if (token) {
        try {
          const result = await inviteUsersWithRoles(token, data);
          invitesCountFef.current = (result.length)

          result.forEach(({id} : any) => {
            checkStatus(token, id, 0);
          })
          setTimeout(clearSelectedRows, 200)
        } catch (error) {
          setIsLoading(false)
        }
      }
    }
  }

  const checkStatus = async (token: string, id: string, tryNumber: number) => {
      const result = await getBackgroundProcessStatus(token, id);
      /// Unknown , Running, Completed, ContinuedAsNew, Failed, Canceled, Terminated, Pending
      if(['Completed', 'Failed', 'Canceled'].includes(result?.status)) {
        if(invitesCountFef.current && invitesCountFef.current === 1) {
          setShowSuccessMessage(true)
          setTimeout(() => {
            setShowSuccessMessage(false);  
          }, 4000);
          await loadData()
        }
        if(invitesCountFef.current)
          invitesCountFef.current -= 1;
      } else {
        setTimeout(() => checkStatus(token, id, ++tryNumber), 5000 + (tryNumber * 2000))
      }
  }

  const updateUserRole = async (userId: number, roleId:number, isAdd: boolean) => {
    if (isAuthenticated) {

      const data: UpdateUserRoleRequest = { userId, roleId };
      const token: string = await getTokenSilently();
      if (token) {
        try {
          if(isAdd)
            await addUserRole(token, data);
          else
            await removeUserRole(token, data);
        
        if(typeof setUpdateTotals === 'function') setUpdateTotals(new Date())

        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  const onchangeRole = (e: any, data: any, props: any) => {
    const newSelection = selected
    const row = newSelection.find(e => e.email === props.email);
    let roles: Array<any> = [];
    if (row) {
      roles = row.roles;
    } else {
      roles = props.groups.map((p: any) => parseInt(p.replace("roleGroup", ""), 10))
    }
    roles = roles.filter((p: any) => p !== data.roleId)
    if (e.checked) roles.push(data.roleId)
      
    updateUserRole(props.uid, data.roleId, e.checked)

    if (row) {
      row.roles = roles;
    } else {
      newSelection.push({
        email: props.email,
        roles
      });
    }

    if(typeof updateRolesOnGrid === 'function') updateRolesOnGrid(props.uid, roles)

    setSelected(newSelection)
  }

  const editRoleTemplate = (props: any, item: any) => (
      <div>
        <CheckBoxComponent
          change={(e: any) => onchangeRole(e, item, props)}
          checked={props.groups.includes(`roleGroup${item.roleId}`)}
          data-testid={`grid-role-${item.roleId}`}
        />
      </div>
    )

  const groupColumn = (item: RoleDTO) => ({
    field: `roleGroup${item.roleId}`,
    headerText: item.roleName,
    textAlign: 'Center',
    allowEditing: false,
    allowResizing: true,
    template: (props: any) => editRoleTemplate(props, item),
    customAttributes: { class: 'circle-skeleton' }
  });

  const loadData = async () => {
    setIsLoading(true)
    if (isAuthenticated) {
      const token: string = await getTokenSilently();
      if (token) {
        const resultUsers = await getUsers(token);
        const resultRoles = await getRoles(token);
       setDataSource((resultUsers || []).map((item: UserDTO) => {
          const row : Anything = { 
              uid: item.userId,
              name: item.displayName, 
              email: item.mail, 
              groups: item?.groups?.map(i => `roleGroup${i}`), 
              accountStatusName: item.accountStatusName, 
              lastLoggedInDateTime: item.lastLoggedInDateTime,
              lastLoggedInDateTimeString: item.lastLoggedInDateTimeString,
              employeeTitle: item.employeeTitle,
              employeeHomeCompanyName: item.employeeHomeCompanyName,
              employeeHomeOrganizationName: item.employeeHomeOrganizationName,
              isSelected: false,
            }

          resultRoles.forEach((role : any) => {
            row[`roleGroup${role.roleId}`] = item?.groups?.includes(role.roleId) ? "1" : "0"
          })
          return row;
        }))

        setColumns([...fixedColumns, ...(resultRoles || []).map(groupColumn), ...extraColumns])
        setFilters([...fixedColumns, ...(resultRoles || []).map(groupColumn), ...extraColumns])
      }
    }
    setIsLoading(false)
  }

  const setFilters = (instance:any) => {
    if(instance) {
      let filters : any[] = []
      instance.forEach((item:any) => {
        if (item?.type !== "checkbox" && !item.isPrimaryKey) {
          const name = item.field
          const filter = localStorage.getItem(`${name}-filter`)
          if (filter!==null)
            filters.push(JSON.parse(filter))
        } 
      })
      setFilterColumns(filters)
    }
  }

  const rowDataBound = (args: any) => {
    if (!isSelectable(args.data.groups, args.data.accountStatusName)) {
      args.isSelectable = false;
      args.row.classList.add('e-disabled-invitation')
    }
    if(args.data.accountStatusName === 'Sending Invitation')
      args.row.classList.add('e-disabled')
  }

  const handleSwitchButton = () => setSwitchChecked(!swicthChecked)

  const handleShowModalInvitation = () => setModalInvitationsVisible(!modalInvitationsVisible);

  useEffect(() => {
    loadData()
  }, [isAuthenticated, getTokenSilently])

  useEffect(() => {
    if (isHyperFocus) {
      elementWithScroll?.removeEventListener("scroll", handlerScroll);
      eGridcontent?.removeEventListener("scroll", handlerScrollGrid);
      document.querySelector(`#${elementWithScroll?.id} .l-subheader`)?.classList.remove('l-subheader--fixed')
      document.querySelector(`#${elementWithScroll?.id} .grid-container-outside`)?.classList.remove('grid-container-outside--fixed')

      elementWithScroll = document.getElementById("user-management-hyperfocus")
    }
    else {
      elementWithScroll = document.getElementById('l-main-content-principal');
    }
    eGridcontent = document.querySelector(`#${elementWithScroll?.id} .e-content`);
    elementTitle = document.querySelector(`#${elementWithScroll?.id} .l-subheader`);
    elementGrid = document.querySelector(`#${elementWithScroll?.id} .grid-container-outside`);

    function handlerScrollGrid(this: HTMLElement) {
      elementWithScroll?.scroll({ top: this.scrollTop === 0 ? 0 : 400, behavior: "smooth" })
      if (this.scrollTop === 0) {
        elementTitle?.classList.remove('l-subheader--fixed')
        elementGrid?.classList.remove('grid-container-outside--fixed')
      }
    }

    function handlerScroll(this: HTMLElement) {
      if (elementTitle && elementGrid) {
        if (this.scrollTop >= elementTitle.scrollTop) {
          elementTitle.classList.add('l-subheader--fixed')
          elementGrid.classList.add('grid-container-outside--fixed')
        }
        else {
          elementTitle.classList.remove('l-subheader--fixed')
          elementGrid.classList.remove('grid-container-outside--fixed')
        }
      }
    }

    elementWithScroll?.addEventListener("scroll", handlerScroll);
    eGridcontent?.addEventListener("scroll", handlerScrollGrid);

    return () => {
      elementWithScroll?.removeEventListener("scroll", handlerScroll);
      eGridcontent?.removeEventListener("scroll", handlerScrollGrid);
    }
  }, [dataSource, isHyperFocus, isLoading])

  const handleShowAddUser = () => setShowAddUserModal(!showAddUserModal)

  return {
    dataSource,
    columns,
    isLoading,
    selectedRows,
    handleShowAddUser,
    showAddUserModal,
    clearSelectedRows,
    selectAllRows,
    onSendInvites,
    rowDataBound,
    handleShowModalInvitation,
    handleSwitchButton,
    modalInvitationsVisible,
    swicthChecked,
    filterColumns,
    showSuccessMessage,
    loadData,
  };
};
export default useUserManagement;
