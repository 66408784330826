import React, { useEffect, useState } from "react";
import useAcquireToken from "../../AuthToken/Hooks/useAcquireToken";
import type { CreateUserRequest } from "../../../apiclients/bstinsights";
import useBSTInsightsAPI from "../../../apiclients/api/bstinsightsapi";
import { isValidEmail } from "../../../utils/validations";
import { useIsAuthenticated } from "@azure/msal-react";

let timeout: any = null;
/**
*
* Returns necessary props to load Add User Hyperfocus
*
* @returns 
* ```
* {
*    handleCreatButton,
*    disabledButton,
*    handleSubmitButton,
*    handleUsername,
*    handleEmail,
*    handleUserRoles,
*    handleResetForm
*    createUser
*    disabledButton,
*    username,
*    email,
*    userRoles,
*    dataUserRoles,
*  }
*```
*/
const useAddUser = () => {
  const [disabledButton, setDisabledButton] = useState<boolean>(true)
  const [isSendInvitationEnabled, setIsSendInvitationEnabled] = useState<boolean>(true)
  const [username, setUsername] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [userRoles, setUserRoles] = useState<Array<any>>([])
  const [dataUserRoles, setDataUserRoles] = useState<Array<any>>([])
  const [accessToken, setAccessToken] = useState("");
  const { saveUser, getRoles, userExists } = useBSTInsightsAPI();
  const isAuthenticated = useIsAuthenticated();
  const { getTokenSilently } = useAcquireToken();

  const [emailExists, setEmailExists] = useState(null);
  
  useEffect(() => {
    loadDataRoles()
  }, [isAuthenticated, getTokenSilently])

  const handleUsername = (e: any) => {
    setUsername(e.target.value)
  }

  const handleEmail = async (e: any) => {
    console.log('handleEmail', e)
    setEmail(e.target.value)
    setEmailExists(null)
    clearTimeout(timeout)
    if(isValidEmail(e.target.value)) {
      timeout = setTimeout(async () => {
        const token: string = await getTokenSilently();
        const result = await userExists(token, e.target.value);
        setEmailExists(result)
      }, 1000)
  }
}

  const handleUserRoles = (e: any) => {
      setUserRoles(e)
  }

  const handleSubmitButton = async (sendInvitation: boolean) => {
    return await createUser(username, email, userRoles, sendInvitation)
  }

  const handleResetForm = () => {
    setUsername("")
    setEmail("")
    setUserRoles([])
  }

  useEffect(() => {
    setDisabledButton(!(username?.trim() !== '' && email?.trim() !== '' && isValidEmail(email) && emailExists === false));
  }, [username, email, emailExists]);

  useEffect(() => {
    setIsSendInvitationEnabled(userRoles && userRoles.length > 0)
  }, [userRoles]);

  const loadDataRoles = async () => {

    if (isAuthenticated) {
    const token: string = await getTokenSilently();
      const resultRoles = await getRoles(token);
      setDataUserRoles(resultRoles)
    }
  }


  const createUser = async (name: string, email: string, roleIds: Array<number>, sendInvitation: boolean) => {
    const request: CreateUserRequest = {
      displayName: name,
      email,
      sendInvitation,
      roleIds
    };

    const userResponse = await saveUser(accessToken, request);
    return userResponse
  };

  useEffect(() => {
    if (isAuthenticated) {
      getTokenSilently().then((token: string) => {
        setAccessToken(token)
      });
    }
  }, [
    getTokenSilently,
    isAuthenticated,
  ]);

  return {
    handleSubmitButton,
    handleUsername,
    handleEmail,
    handleUserRoles,
    handleResetForm,
    disabledButton,
    username,
    email,
    emailExists,
    userRoles,
    dataUserRoles,
    isSendInvitationEnabled,
    createUser
  };
};

export default useAddUser;
