import React, { useEffect } from "react";
import ContentLoader from "react-content-loader";
import useSummaryTop from "../Hooks/useSummaryTop";

/**
 *
 * Render Summary Top with count and name
 *
 **/

type propsTotal = {
  updateTotals?: any;
};

const SummaryTop = ({ updateTotals }: propsTotal) => {
  const { summaryItems, isLoading, loadData } = useSummaryTop();

  useEffect(() => {
    if (updateTotals) loadData(updateTotals instanceof Date);
  }, [updateTotals]);

  if (!isLoading && summaryItems && summaryItems.length === 0) return null;

  return (
    <React.Fragment>
      <section className="summary-top" data-testid="summarytop">
        <div className="global-utilities__container">
          <div className="summary-top__content">
            {isLoading &&
              [0, 1, 2, 3, 4].map((key) => (
                <div
                  key={`loading-${key}`}
                  className="summary-top__block"
                  data-testid="loading"
                >
                  <ContentLoader style={{ height: 50, width: 120 }}>
                    <rect x="46" y="0" rx="25" ry="25" width="28" height="28" />
                    <rect
                      x="0"
                      y="36"
                      rx="15"
                      ry="15"
                      width="120"
                      height="14"
                    />
                  </ContentLoader>
                </div>
              ))}
            {!isLoading &&
              summaryItems.map(({ count, displayname, id }) => (
                <div
                  key={`summary-${id}`}
                  className="summary-top__block"
                  data-testid={displayname}
                >
                  <p className="heading-md">{count}</p>
                  <span className="label-sm">{displayname}</span>
                </div>
              ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SummaryTop;
