import React, { useEffect, useState } from "react";

import { Filter, Sort, Resize, ColumnChooser, ColumnDirective, ColumnsDirective, GridComponent, Inject, Freeze, DataResult, SelectionSettingsModel, TextAlign, Selection, Edit, EditSettingsModel, VirtualScroll } from '@syncfusion/ej2-react-grids';
import { DataManager } from '@syncfusion/ej2-data';

/**
 * Column interface
 * 
 * @param field - The field name. Necessary to map the data source values in Grid columns
 * @param type -  The type of the data the column bounded
 * @param headerText - Default header title of the column
 * @param width -  The column width
 * @param textAlign - The column text align
 * @param isPrimaryKey - Sets the column value like a unique key
 * @param allowEditing - If set to false, then it disables editing of a particular column. By default all columns are editable.
 * @param format - To format cell values based on specific culture (Ex: currency or datetime)
 * @param template - Defines the column template that renders customized element in each cell of the column. It accepts either template string or HTML element ID
 *
 * @remarks 
 * This parameters is part of the {@link https://ej2.syncfusion.com/react/documentation/grid/}.
 *
 **/
export type Column = {
    uid?: string,
    field?: string,
    type?: string,
    headerText?: string,
    headerTemplate?: any,
    width?: number | string,
    textAlign?: TextAlign,
    isPrimaryKey?: boolean,
    allowEditing?: boolean,
    allowResizing?: boolean,
    visible?: boolean,
    format?: string,
    template?: any,
    customAttributes?: any,
}


/**
 *
 * The Grid component is based on Syncfusion Grid is used to display and manipulate tabular data with configuration options to control the way the data is presented and manipulated.
 * @param id - Unique identifier of Grid
 * @param rowSelected - Function used to select row
 * @param rowDeselected - Function used to deselect row
 * @param rowDeselected - Function used to deselect row
 * @param onSendInvites - Function used to handle with click of send button
 * @param rowDataBound - Triggers when Datasource is populated in the grid
 * @param selectedRowsNumber - Function used to deselect row
 * @param isLoading - Boolean used to show if grid is loading
 * @param dataSource - Array of objects used to render grid table rows
 * @param columns - Array of objects based on Interface Column
 * @param selectionSettings - Defines the type of selection (Ex: multiple, simple, row, cell, etc..)
 * @param editSettings - Configures the edit behavior of the Grid.
 * @param frozenColumns - Set the number of columns to freeze
 * @param actionBegin - Triggers when Grid actions such as sorting, filtering, paging, grouping, edit etc. starts.
 * @param actionComplete - Triggers when Grid actions such as sorting, filtering, paging, grouping, edit etc. are completed.
 * @param showFooter - Boolean used to show/hide grid footer
 * @param showAddUserModal - Boolean used to open the hyperfocus to add a new User
 * @param selectAll - Boolean used to select all rows of the grid
 *
 * @remarks 
 * Syncfusion Grid documentation {@link https://ej2.syncfusion.com/react/documentation/grid/}.
 *
 **/

interface GridProps {
    id?: string,
    isLoading?: boolean,
    dataSource: Object | DataManager | DataResult,
    columns: Column[],
    selectionSettings?: SelectionSettingsModel,
    editSettings?: EditSettingsModel,
    frozenColumns?: number
    selectedRowsNumber?: number
    rowSelected?: (e: any) => void,
    rowDeselected?: (e: any) => void,
    handleShowAddUser?: (e: any) => void,
    onSendInvites?: (e: any) => void,
    rowDataBound?: (e: any) => void,
    actionBegin?: (e: any) => void,
    actionComplete?: (e: any) => void,
    load?: (e: any) => void,
    showFooter?: boolean
    showAddUserModal?: boolean
    selectAll?: boolean,
    gridInstance?: any,
    filterColumns?: any,
}

/**
 *
 * The Grid component is based on Syncfusion Grid is used to display and manipulate tabular data with configuration options to control the way the data is presented and manipulated.
 * @param id - Unique identifier of Grid
 * @param rowSelected - Function used to select row
 * @param rowDeselected - Function used to deselect row
 * @param rowDeselected - Function used to deselect row
 * @param onSendInvites - Function used to handle with click of send button
 * @param rowDataBound - Triggers when Datasource is populated in the grid
 * @param selectedRowsNumber - Selected roes count
 * @param isLoading - Boolean used to show if grid is loading
 * @param dataSource - Array of objects used to render grid table rows
 * @param columns - Array of objects based on Interface Column
 * @param selectionSettings - Defines the type of selection (Ex: multiple, simple, row, cell, etc..)
 * @param frozenColumns - Set the number of columns to freeze
 * @param showFooter - Boolean used to show/hide grid footer
 * @param showAddUserModal - Boolean used to open the hyperfocus to add a new User
 * @param selectAll - Boolean used to select all rows of the grid
 *
 * @remarks 
 * Syncfusion Grid documentation {@link https://ej2.syncfusion.com/react/documentation/grid/}.
 *
 **/
const Grid = (
    { id,
        rowSelected,
        rowDeselected,
        onSendInvites,
        rowDataBound,
        actionBegin,
        selectedRowsNumber,
        isLoading,
        dataSource,
        selectionSettings,
        editSettings,
        selectAll,
        columns, frozenColumns, showFooter, gridInstance, filterColumns }: GridProps) => {
    const [windowHeight, setWindowHeight] = useState(0)
    useEffect(() => {
        setWindowHeight(window.outerHeight);
        window.addEventListener("resize", () => {
            setWindowHeight(window.outerHeight);
        });
        return () => {
            window.removeEventListener("resize", () => {
                setWindowHeight(window.outerHeight);
            })
        }
    }, []);

    useEffect(() => {
        if (selectAll) gridInstance.current?.selectRows([0, 1200])
    }, [selectAll])

    const sortComparer = (reference: any, comparer: any) => {
        
        if(typeof reference === 'string' || typeof comparer === 'string')
            return (reference || '').localeCompare((comparer || ''));

        if (reference < comparer) {
            return -1;
        }
        if (reference > comparer) {
            return 1;
        }
        return 0;
    };

    const resizeStop = (props: any) => {
        if (props.column.field !== undefined)
            localStorage.setItem(`col-${props.column.field}`, `${props.column.width}`)
    }

    if (!isLoading && !(columns && columns.length > 0))
        return null;


    const gridFooter = (
        <React.Fragment>
            <div className="info-table__footer">
                <div className="global-utilities__container">
                    <div className="info-table__footer-left">
                        <span className="invite-title">{selectedRowsNumber} User selected</span>
                        <span className="invite-text">Click send invitation button to inform users.</span>
                    </div>
                    <div className="info-table__footer-right">
                        <button className="DNA-button" data-testid="btn-send-invitation" onClick={onSendInvites}>
                            {selectedRowsNumber && selectedRowsNumber > 0 ? 'Send invitations' : 'Send invitation'}
                            <i className="icon icon-send"></i>
                        </button>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )


    if (!columns || columns.length === 0) return null;

    const setFiltering = (props: any, gridteste: any) => {

        const grid = gridInstance.current
        const columnName = props.columnName;
        if (props.filterModel.cBox) {
            props.filterModel.cBox.addEventListener('click', function (e: any) {
                setTimeout(() => {
                    const selectedInputs = e.srcElement.closest(`.e-checkboxlist`)?.querySelectorAll('input[type=checkbox]:checked')
                    const selected: any[] = []
                    selectedInputs.forEach((element: any) => {
                        const item = element.parentElement.querySelector('.e-label').innerText                        
                        if('Select All' !== item) selected.push(item)
                    });
                    grid.filterByColumn(columnName, 'notequal', selected.join(`,`), "or", false, true);
                    const properties = {
                        field: columnName,
                        matchCase: true,
                        operator: 'notequal',
                        predicate: "or",
                        value: selected.join(`,`),
                    };
                    localStorage.setItem(`${columnName}-filter`, JSON.stringify(properties))
                }, 100)
            })
        }

        const name = props.currentFilteringColumn
        if (props.action === "clear-filter")
            localStorage.removeItem(`${name}-filter`)
    }

    const actionComplete = (args: any) => {
        const grid = gridInstance.current;
        if (args.requestType === "filterafteropen") {
            setFiltering(args, grid)
        }
    }

    const getfilter = () => (filterColumns)

    const getSortingOptions = () : any => {
        const columnToSort = localStorage.getItem('grid-sort-column') || 'name'
        const direction = localStorage.getItem('grid-sort-direction') || 'Ascending' 
        return  {
            columns: [{ 
                field: columnToSort, 
                direction
            }]
        };
    }

    const actionBeginEvent = (e: any) => {
        if(e?.requestType === 'sorting' && !e?.target?.classList?.contains('e-icons')) {
            e.cancel = true;
        } else if(e?.requestType === 'sorting') {
            localStorage.setItem('grid-sort-column', e.columnName || 'name');
            localStorage.setItem('grid-sort-direction', e.direction || 'Ascending');
        }
        if(typeof actionBegin === 'function')
            actionBegin(e)
    }

    return (
        <React.Fragment>
            <div className={`l-container${showFooter ? " has-selected-items": ""}`} data-testid="grid container" id={id}>
                <GridComponent
                    filterSettings={{ type: 'CheckBox', columns: getfilter() }}
                    sortSettings={getSortingOptions()}
                    showColumnChooser={true}
                    allowSorting={true}
                    allowResizing={true}
                    rowDataBound={rowDataBound}
                    dataSource={dataSource}
                    enableStickyHeader={true}
                    frozenColumns={frozenColumns}
                    loadingIndicator={{ indicatorType: "Shimmer" }}
                    frozenRows={0}
                    enableVirtualization={true}
                    rowHeight={84}
                    height={windowHeight - 300}
                    width='100%'
                    enableHover={true}
                    allowSelection={true}
                    editSettings={editSettings}
                    selectionSettings={selectionSettings}
                    className="info-table"
                    rowSelected={rowSelected}
                    rowDeselected={rowDeselected}
                    ref={gridInstance}
                    actionBegin={actionBeginEvent}
                    actionComplete={actionComplete}
                    resizeStop={resizeStop}
                >
                    <ColumnsDirective>
                        {columns.map((item: Column, index) => {
                            let width = item?.width || "240px";
                            if (item?.type !== "checkbox" && !item.isPrimaryKey) {
                                const local = localStorage.getItem(`col-${item?.field}`);
                                item.width = local || width;
                                if (item?.headerText === 'Name') {
                                    item.width = local || '237px';
                                }
                            }

                            return <ColumnDirective
                                uid={item.uid}
                                data-testid={`row-${index}`}
                                isPrimaryKey={item?.isPrimaryKey}
                                key={`column-${item?.field || index}`}
                                field={item?.field}
                                visible={item?.visible}
                                headerText={item?.headerText}
                                headerTemplate={item?.headerTemplate}
                                minWidth={index > 1 ? 196 : 0}
                                width={item?.width || "280px"}
                                headerTextAlign={item?.textAlign}
                                textAlign={item?.textAlign}
                                format={item?.format}
                                type={item?.type}
                                allowEditing={item?.allowEditing}
                                allowResizing={item?.allowResizing}
                                template={item?.template}
                                sortComparer={sortComparer}
                                customAttributes={item?.customAttributes}
                            />
                        })}
                    </ColumnsDirective>
                    <Inject services={[Filter, Freeze, Selection, Edit, Sort, Resize, ColumnChooser, VirtualScroll]} />
                </GridComponent>

                {showFooter ? gridFooter : null}
            </div>

        </React.Fragment>
    );
};

export default Grid;