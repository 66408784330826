import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../components/NavBar/Views/NavBar";
import ReactGA from "react-ga4";
import ContentNotSupported from "../components/ContentNotSupported/Views/ContentNotSupported";
import { RoleDTO, UserInformationDTO } from "../apiclients/bstinsights";
import useBSTInsightsAPI from "../apiclients/api/bstinsightsapi";
import useAcquireToken from "../components/AuthToken/Hooks/useAcquireToken";
import { useMsal } from "@azure/msal-react";
import ErrorScreen from "../components/ErrorScreen/ErrorScreen";
import Banner from "../components/Banner/Views/Banner";

export type TodayMarkerType = {
  TodayMarker?: string;
  MobileSizeRestriction?: boolean;
  setTodayMarker: Dispatch<SetStateAction<string>>;
  setMobileSizeRestriction: Dispatch<SetStateAction<boolean>>;
};

export const TodayMarkerContext = createContext<TodayMarkerType>({
  setTodayMarker: () => {},
  setMobileSizeRestriction: () => {},
});

export type BSTInsightssAppType = {
  UserInformation: UserInformationDTO;
  UserRoles: RoleDTO[];
  setUserRoles: Dispatch<SetStateAction<RoleDTO[]>>;
  setHasError: Dispatch<SetStateAction<boolean>>;
};

export const BSTInsightsAppContext = createContext<BSTInsightssAppType>({
  UserInformation: {},
  UserRoles: [],
  setUserRoles: () => {},
  setHasError: () => {},
});
/**
 * Page Layout
 *
 * Sets the layout of the website
 *
 * @exports PageLayout
 * @returns a jsx element.
 */

const PageLayout = ({ children }: { children: any }) => {
  const { getUserInformation } = useBSTInsightsAPI();
  const { getTokenSilently } = useAcquireToken();
  const { instance } = useMsal();
  const [TodayMarker, setTodayMarker] = useState("");
  const [MobileSizeRestriction, setMobileSizeRestriction] =
    useState<boolean>(false);
  const [isSupportedScreenSize, setIsSupportedScreenSize] =
    useState<boolean>(true);

  const [UserRoles, setUserRoles] = useState<RoleDTO[]>([]);
  const [UserInformation, setUserInformation] = useState<UserInformationDTO>(
    {}
  );
  const [hasError, setHasError] = useState(false);

  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(
      `${process.env.GOOGLE_ANALYTICS_TRACKING_CODE || "G-XWRTBQ8XHS"}`
    );
  }, []);
  useEffect(() => {
    if (location) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        page_title: document.title,
      });
    }
  }, [location]);

  useEffect(() => {
    getTokenSilently().then((accessToken) => {
      if (accessToken) {
        const activeAccount = instance.getActiveAccount();
        getUserInformation(
          accessToken,
          activeAccount?.localAccountId || ""
        ).then((userInformationResults) => {
          if (userInformationResults) {
            setUserInformation(userInformationResults);
          }
        });
      }
    });
  }, [getTokenSilently, getUserInformation, instance]);

  return (
    <>
      <ContentNotSupported
        isSupportedCallBack={setIsSupportedScreenSize}
        mobileSizeRestriction={MobileSizeRestriction}
      />
      <div className="flex-container">
        <TodayMarkerContext.Provider
          value={{
            TodayMarker,
            setTodayMarker,
            MobileSizeRestriction,
            setMobileSizeRestriction,
          }}
        >
          <BSTInsightsAppContext.Provider
            value={{
              UserInformation: UserInformation,
              UserRoles: UserRoles,
              setUserRoles,
              setHasError,
            }}
          >
            <div
              style={{
                display:
                  isSupportedScreenSize || !MobileSizeRestriction
                    ? "block"
                    : "none",
              }}
            >
              <NavBar />
              <div
                className="flex-container__inner"
                data-testid="flex container inner"
              >
                <main className="l-main">
                  {/* <SectionPlaceholder Name="Drawer" /> */}
                  <section
                    className="l-main__content disable__scrollbar"
                    data-testid="l main content"
                    id="l-main-content-principal"
                  >
                    <Banner />
                    {hasError && <ErrorScreen />}
                    {children}
                  </section>
                </main>
              </div>
            </div>
          </BSTInsightsAppContext.Provider>
        </TodayMarkerContext.Provider>
      </div>
    </>
  );
};
export default PageLayout;
