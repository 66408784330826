import { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import useAcquireToken from "../../AuthToken/Hooks/useAcquireToken";
import useBSTInsightsAPI from "../../../apiclients/api/bstinsightsapi";
import { RoleDTO } from "../../../apiclients/bstinsights";

/**
* Returns necessary props to load the component
*
* @returns
* ```
* {
*   summaryItems,
*   isLoading
* }
* ```
*
**/
const useSummaryTop = () => {
  const { getRoles, getTotalRoles } = useBSTInsightsAPI();
  const isAuthenticated = useIsAuthenticated();
  const { getTokenSilently } = useAcquireToken();

  const [isLoading, setIsLoading] = useState(true);
  const [summaryItems, setSummaryItems] = useState([])
  
  const loadData = async (hideIsloading = false) => {
    if(!hideIsloading) setIsLoading(true) 
    if (isAuthenticated) {
        const token: string = await getTokenSilently();
      if(token) {
        const result = await getRoles(token);
        const totalRoles = await getTotalRoles(token);
        const items = (result || []).map((item: RoleDTO) => (
          { id: item.roleId, displayname: item.roleName, count: item.totalAssignedUsers }
        ) );
        items.unshift({id: totalRoles.roleId, displayname: totalRoles.roleName, count: totalRoles.totalAssignedUsers })
        setSummaryItems(items)
      }
    }
    setIsLoading(false)
  }
  useEffect(() => {
      loadData()
  }, [isAuthenticated, getTokenSilently])

  return {
    summaryItems,
    isLoading,
    loadData
  };
};
export default useSummaryTop;
