import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType, IPublicClientApplication } from "@azure/msal-browser";
import PageLayout from "./layouts/PageLayout";

import { lazy, Suspense, useEffect } from "react";
import Splash from "./components/Splash/Views/Splash";
import RolesAuthRoute from "./utils/RolesAuthRoute";
type AppProps = {
  pca: IPublicClientApplication;
};

type PerformanceOutput = {
  id: string;
  name: string;
  type: string;
  start: number;
  duration: number;
};

const App = ({ pca }: AppProps) => {
  const UserManagement = lazy(() => import("./pages/Views/UserManagement"));
  const Home = lazy(() => import("./pages/Views/Home"));
  let performanceOutput: PerformanceOutput[] = [];

  const observer = new PerformanceObserver((list) => {
    const output = list
      .getEntries()
      .filter(
        (entry) =>
          entry.name.toLowerCase().includes("powerbi") ||
          entry.name.toLowerCase().includes("splash") ||
          entry.name.toLowerCase().includes("/api/")
      )
      .map((entry) => {
        return {
          id: "performance",
          name: entry.name,
          type: entry.entryType,
          start: entry.startTime,
          duration: entry.duration,
        };
      });
    performanceOutput = performanceOutput.concat(output);
  });

  observer.observe({
    entryTypes: ["navigation", "resource", "measure", "mark"],
    buffered: true,
  });
  performance.mark("splash.start");
  useEffect(() => {
    setTimeout(() => {
      const startPowerBIReport = performanceOutput.find(
        (item: PerformanceOutput) => item.name.includes("Loaded.Called")
      );
      const splashStart = performanceOutput.find((item: PerformanceOutput) =>
        item.name.includes("Render.Splash")
      );
      const endPowerBIReport = performanceOutput
        .reverse()
        .find((item: PerformanceOutput) =>
          item.name.includes("Loading.Complete")
        );
      const splashEnd = performanceOutput.find((item: PerformanceOutput) =>
        item.name.includes("Remove.Splash")
      );

      const calculatedResults: PerformanceOutput[] = [
        {
          id: "performance",
          name: "powerbi calculated load time",
          type: "caculated",
          start: startPowerBIReport?.start || 0,
          duration:
            (endPowerBIReport?.start || 0) - (startPowerBIReport?.start || 0),
        },
        {
          id: "performance",
          name: "splash calculated load time",
          type: "caculated",
          start: splashStart?.start || 0,
          duration: (splashEnd?.start || 0) - (splashStart?.start || 0),
        },
      ];

      const output = performanceOutput.filter(
        (item) =>
          item.name.toLowerCase().includes("/api/") ||
          item.name.toLowerCase().includes("app.powerbi.com")
      );

      window.postMessage(output.concat(calculatedResults), "*");
    }, 30000);
  });

  return (
    <Suspense fallback={<Splash />}>
      <MsalProvider instance={pca}>
        <BrowserRouter>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
          >
            <Splash />
            <PageLayout>
              <Routes>
                <Route
                  path="/"
                  element={
                    <RolesAuthRoute roles={[]} route="Home">
                      <Home />
                    </RolesAuthRoute>
                  }
                />
                <Route
                  path="/UserManagement"
                  element={
                    <RolesAuthRoute
                      roles={["Account Owner"]}
                      route="UserManagement"
                    >
                      <UserManagement />
                    </RolesAuthRoute>
                  }
                />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </PageLayout>
          </MsalAuthenticationTemplate>
        </BrowserRouter>
      </MsalProvider>
    </Suspense>
  );
};

export default App;
