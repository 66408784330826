import React from "react";
import InputIcon from "../../InputIcon/InputIcon"
import { useTranslation } from "react-i18next";


/**
*
* Render AddUser component with Form and Handlers
*
* @param handleUsername - Function that handles with username form field
* @param handleEmail - Function that handles with email form field
* @param handleUserRoles - Function that handles with user roles checkboxes form field
* @param username - Returns the value of username form field
* @param email - Returns the value of email form field
* @param emailExists - Check for existing matching email address
* @param userRoles - Returns the value of user roles form field
* @param dataUserRoles - Bring the data array items to compose user roles checkboxes
*
* @privateRemarks
* @state
*/
type props = {
  state: any;
}

const AddUser = ({ state }: props) => {
  const { t } = useTranslation();
  const
    {
      handleUsername,
      handleEmail,
      handleUserRoles,
      username,
      email,
      userRoles,
      dataUserRoles,
      disabledButton,
      emailExists
    } = state;

  return (
    <div className="add-user-modal-content" data-testid="AddUser-box">
      <InputIcon
        label={t("addUser.labelName")}
        testid="user-name"
        type="text"
        iconName="profile"
        onChange={handleUsername}
        value={username}
        autoFocus={true}
      />
      <InputIcon
        label={t("addUser.labelEmail")}
        testid="user-email"
        type="email"
        iconName="email"
        onChange={handleEmail}
        value={email}
        errorMessage={t("addUser.emailExists")}
        isValid={emailExists !== true}
      />
      <InputIcon
        label={t("addUser.labelUserRoles")}
        testid="user-roles"
        type="select"
        iconName="pricing"
        selectValue={userRoles}
        onChange={handleUserRoles}
        dataSource={dataUserRoles}
        disabled={disabledButton}
      />

    </div>
  );
};
export default AddUser;
