type Option = {
  value: number | string;
  label: string;
}

type Props = {
  value: Option['value'] | null;
  options: Option[];
  onChange: (value: Option['value'] | null) => void;
  disabled?: boolean;
}

export const SelectControl = ({
  value,
  options,
  onChange,
  ...rest
}: Props) => {
  return (
    <select
      {...rest}
      value={value || ""}
      className="input-control"
      data-testid="select-control"
      onChange={(event) => onChange(event.target.value)}
    >
      {options.map(({ value, label }) => (
        <option key={value} value={value}>{label}</option>
      ))}
    </select>
  );
};
