import { Configuration, PopupRequest } from "@azure/msal-browser";

const AzureActiveDirectoryAppClientId: any =
  process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID;
const AzureActiveDirectoryAuthority: any =
  process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_AUTHORITY;
const AzureActiveDirectoryRedirectURL: any =
  process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_REDIRECT_URL;

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: AzureActiveDirectoryAppClientId,
    authority: AzureActiveDirectoryAuthority,
    redirectUri: AzureActiveDirectoryRedirectURL,
    postLogoutRedirectUri: "/",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["openid"], //["https://analysis.windows.net/powerbi/api/.default"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
};
