import { useEffect, useState } from "react";
import useBSTInsightsAPI from "../../../apiclients/api/bstinsightsapi";
import useAcquireToken from "../../AuthToken/Hooks/useAcquireToken";

const useBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const { getPredictionErrors } = useBSTInsightsAPI();
  const { getTokenSilently } = useAcquireToken();

  useEffect(() => {
    getTokenSilently().then((accessToken) => {
      if (accessToken) {
        getPredictionErrors(accessToken).then((predictionErrorResults) => {
          if (predictionErrorResults) {
            if (predictionErrorResults.error) {
              setShowBanner(true);
              setTimeout(() => {
                setShowBanner(false);
              }, 5000);
            }
          }
        });
      }
    });
  }, [getPredictionErrors, getTokenSilently]);

  return { showBanner };
};

export default useBanner;
