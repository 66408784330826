import React, { useContext } from "react";
import { TodayMarkerContext } from "../../layouts/PageLayout";

const TodayMarker = () => {
  const { TodayMarker, setTodayMarker } = useContext(TodayMarkerContext);
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          gap: "10px",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "16px",
        }}
      >
        <span>Enter date for Today Marker</span>
        <input
          placeholder="mm.dd.yyyy"
          value={TodayMarker}
          onChange={(event) => {
            setTodayMarker(event.currentTarget.value);
          }}
        ></input>
      </div>
    </React.Fragment>
  );
};

export default TodayMarker;
