import * as React from 'react';
import MultiselectDropdown from '../MultiselectDropdown/Views/MultiselectDropdown';


interface Props {
  iconName?: string,
  label?: string,
  type?: string,
  placeholder?: string,
  errorMessage?: string,
  testid?: string,
  value?: any,
  selectValue?: any,
  disabled?: boolean,
  isValid?: boolean,
  dataSource?: any[],
  autoFocus?: boolean,
  onChange?: (event: any) => void;
}

const InputIcon = ({ label, iconName, type, placeholder, value, selectValue, onChange, dataSource, testid, disabled, isValid, errorMessage, autoFocus }: Props) => {

  const [inputActive, setInputActive] = React.useState<boolean>(false)
  const [focused, setFocused] = React.useState<boolean>(false)

  React.useEffect(() => {
    if ((value && value.trim() !== '') || (selectValue && selectValue.length > 0) || focused) {
      setInputActive(true)
      return
    }
    setInputActive(false)
    return
  }, [value, focused, selectValue]);

  return (
    <>
      <div className={`input-icon ${isValid === false ? "input-error" : ""} ${inputActive ? "input-icon--active" : ""}`}>
        <i data-testid="input-icon" className={`input-icon__icon icon icon-${iconName}`} aria-hidden="true"></i>
        <div className={`input-icon__container ${type} ${type === "select" ? "global-utilities__multiselect" : ""}`}>
          {type === "select" ? (
            <>
              <MultiselectDropdown
                onChange={onChange}
                placeholder={label}
                dataSource={dataSource}
                disabled={disabled}
                value={selectValue}
                fields={{ text: 'roleName', value: 'roleId' }}
                popupHeight="224px"
              />
            </>
          ) : (
            <input
              className="input-icon__input"
              type={type}
              data-testid={testid}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
              value={value}
              autoFocus={autoFocus}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
          )}
          {type !== "select" && <label className="input-icon__label">{label}</label>}
        </div>
      </div>
      {isValid === false && (<span className='input-error-message'>{errorMessage}</span>)}
    </>
  );
};

export default InputIcon;
