import React from "react";
import { Modal } from "react-bootstrap";
import { HyperFocusCloseBtn } from "./HyperFocusCloseBtn";

export interface Props {
  show: boolean;
  handleShowModal?: () => void;
  idBody?: string;
  title?: string;
  className?: string;
  children: any;
  // Project Details Modal don't need header.
  hideModalHeader?: boolean;
  onModalBodyScroll?: React.UIEventHandler<HTMLDivElement>;
}

const HyperFocus = ({
  title,
  show,
  handleShowModal,
  children,
  idBody,
  className,
  hideModalHeader,
  onModalBodyScroll,
}: Props) => {
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleShowModal}
        className={`global-utilities__hyperFocus ${className}`}
        dialogClassName="content-center dialog"
        backdrop="static"
        animation={false}
        fullscreen={true}
        keyboard={false}
      >
        {!hideModalHeader && (
          <Modal.Header>
            <Modal.Title className="heading-modal">{title}</Modal.Title>
            <HyperFocusCloseBtn onCloseHandler={handleShowModal} />
          </Modal.Header>
        )}
        <Modal.Body
          id={idBody}
          data-testid="modal-body"
          onScroll={onModalBodyScroll}
        >
          {children}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default HyperFocus;
