/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Filter } from "../models/Filter";
import type { PredictionError } from "../models/PredictionError";
import type { Sort } from "../models/Sort";
import type { View } from "../models/View";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ProjectPredictionService {
  /**
   * @param azureIdentityId
   * @param unrestrictedAccess
   * @returns View
   * @throws ApiError
   */
  public static projectPredictionGetViews(
    azureIdentityId?: string | null,
    unrestrictedAccess?: boolean
  ): CancelablePromise<Array<View>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/ProjectPrediction/GetViews",
      query: {
        azureIdentityId: azureIdentityId,
        unrestrictedAccess: unrestrictedAccess,
      },
    });
  }

  /**
   * @param azureIdentityId
   * @param unrestrictedAccess
   * @returns Filter
   * @throws ApiError
   */
  public static projectPredictionGetFilters(
    azureIdentityId?: string | null,
    unrestrictedAccess?: boolean
  ): CancelablePromise<Array<Filter>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/ProjectPrediction/GetFilters",
      query: {
        azureIdentityId: azureIdentityId,
        unrestrictedAccess: unrestrictedAccess,
      },
    });
  }

  /**
   * @param azureIdentityId
   * @param unrestrictedAccess
   * @returns Sort
   * @throws ApiError
   */
  public static projectPredictionGetSorts(
    azureIdentityId?: string | null,
    unrestrictedAccess?: boolean
  ): CancelablePromise<Array<Sort>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/ProjectPrediction/GetSorts",
      query: {
        azureIdentityId: azureIdentityId,
        unrestrictedAccess: unrestrictedAccess,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static projectPredictionClearAllNlmPredictions(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/ProjectPrediction/ClearAllNLMPredictions",
    });
  }

  /**
   * @returns PredictionError
   * @throws ApiError
   */
  public static projectPredictionGetPredictionErrors(): CancelablePromise<PredictionError> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/ProjectPrediction/GetPredictionErrors",
    });
  }
}
