import { useTranslation } from "react-i18next";

import { Avatar } from "../../Avatar/View/Avatar";
import { useProfileSection } from '../Hooks/useProfileSection';

export const ProfileSection = () => {
  const { t } = useTranslation();
  const { userInfo, profileSectionItems } = useProfileSection();
  return (
    <div className="profile-section">
      <div className="profile-section__header">
        <div className="profile-section__avatar">
          <Avatar
            abbr={userInfo.initials || "-"}
            width={96}
            height={96}
          />
          <div className="profile-section__avatar-edit"><i className="icon icon-camera" /></div>
        </div>
        <div className="profile-section__user">
          <div className="profile-section__user-name">{userInfo.userName}</div>
          <div className="profile-section__user-role">{userInfo.title}</div>
        </div>
      </div>
      <div className="profile-section__body">
        {profileSectionItems.map(((item) => (
          item.show && (
            <div className="profile-section__item">
              <div className="profile-section__item-label">
                <i className={`icon ${item.icon}`} />
                <span>{t(`profileSection.${item.id}`)}</span>
              </div>
              <div className="profile-section__item-value">{userInfo[item.id]}</div>
            </div>
          )
        )))}
      </div>
    </div>
  );
};
