import { useTranslation } from "react-i18next";
import TodayMarker from "../../TodayMarker/TodayMarker";
import NLMSystemOverrides from "./NLMSystemOverrides";
import NLMProjectOverrides from "./NLMProjectOverrides";
import useNLMThresholdSettings from "../Hooks/useNLMThesholdSettings";
import { NLMModelRunDetails } from "./NLMModelRunDetails";
import NLMProjectSkeleton from "./NLMProjectSkeleton";

const NLMThresholdSettings = () => {
  const env = process.env.REACT_APP_ENV;
  const { t } = useTranslation();
  const { projects, hasAccountOwnerRole, hasProjectAdministrator } =
    useNLMThresholdSettings();
  return (
    <div>
      <div
        className="nlm-threshold-settings__header"
        data-testid="nlm-threshold-settings__header"
      >
        {t("nlmsettings.nlm_model")}
      </div>
      <div className="nlm-threshold-settings__body">
        {env !== "prod" && <TodayMarker />}
        {hasAccountOwnerRole && <NLMSystemOverrides />}
        {hasAccountOwnerRole && <NLMModelRunDetails />}

        {!projects && <NLMProjectSkeleton />}
        {projects && hasProjectAdministrator && (
          <NLMProjectOverrides projects={projects} />
        )}
      </div>
    </div>
  );
};

export default NLMThresholdSettings;
