/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProjectDTO } from "../models/ProjectDTO";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ProjectService {
  /**
   * @param hasPredictions
   * @param hasOverrides
   * @param projectSearchText
   * @returns ProjectDTO
   * @throws ApiError
   */
  public static projectGetProjects(
    hasPredictions?: boolean | null,
    hasOverrides?: boolean | null,
    projectSearchText?: string | null
  ): CancelablePromise<Array<ProjectDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Project/GetProjects",
      query: {
        hasPredictions: hasPredictions,
        hasOverrides: hasOverrides,
        projectSearchText: projectSearchText,
      },
    });
  }
}
