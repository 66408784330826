/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GlobalThresholdDTO } from "../models/GlobalThresholdDTO";
import type { ProjectDTO } from "../models/ProjectDTO";
import type { ProjectThresholdDTO } from "../models/ProjectThresholdDTO";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class MachineLearningSettingsService {
  /**
   * @returns GlobalThresholdDTO
   * @throws ApiError
   */
  public static machineLearningSettingsGetGlobalThreshold(): CancelablePromise<GlobalThresholdDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/MachineLearningSettings/GetGlobalThreshold",
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static machineLearningSettingsUpdateGlobalThreshold(
    requestBody: GlobalThresholdDTO
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/MachineLearningSettings/UpdateGlobalThreshold",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns ProjectThresholdDTO
   * @throws ApiError
   */
  public static machineLearningSettingsGetProjectThresholds(): CancelablePromise<
    Array<ProjectThresholdDTO>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/MachineLearningSettings/GetProjectThresholds",
    });
  }

  /**
   * @returns ProjectDTO
   * @throws ApiError
   */
  public static machineLearningSettingsGetProjectsForProjectThresholds(): CancelablePromise<
    Array<ProjectDTO>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/MachineLearningSettings/GetProjectsForProjectThresholds",
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static machineLearningSettingsMergeProjectThreshold(
    requestBody: ProjectThresholdDTO
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/MachineLearningSettings/MergeProjectThreshold",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static machineLearningSettingsDeleteProjectThreshold(
    requestBody: ProjectThresholdDTO
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/MachineLearningSettings/DeleteProjectThreshold",
      body: requestBody,
      mediaType: "application/json",
    });
  }
}
